import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { environment } from 'src/environments/environment';
import { UserCreationComponent } from '../user-creation/user-creation.component';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, AfterContentChecked, AfterViewInit {

	user: any;
	isEditMode: boolean = false;

	@ViewChild('usergrid', { static: false }) usergrid;
	@ViewChild('userContextMenu', { static: false }) userContextMenu;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private aiAssist: AiAssistService,
		public dialog: MatDialog,
		public notifierService: NotifierService) { }


	ngOnInit() {
		this.loadUsers();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit() {
		document.addEventListener('contextmenu', event => event.preventDefault());
	}

	height;
	width;
	user_creation_height;
	users_url = environment.url + '/api/v2/user/list';
	mediaType = 'GET';
	dataAdapter: any;
	page_title = 'Users List'

	getCellWidth() {
		var number_of_columns = 10;
		return (100 / number_of_columns).toFixed(2) + '%';
	}
	counter: number = 1;
	tooltiprenderer = (element: any): void => {
		let id = `toolTipContainer${this.counter}`;
		element[0].id = id;
		let content = element[0].innerHTML;
		setTimeout(_ => jqwidgets.createInstance(`#${id}`, 'jqxTooltip', { position: 'mouse', content: content }))
		this.counter++;
	}

	columns: any[] = [
		{
			text: 'User Name',
			datafield: 'username',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'First Name',
			datafield: 'firstName',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Last Name',
			datafield: 'lastName',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		},
		{
			text: 'Email ID',
			datafield: 'email',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		},
		{
			text: 'SR Engine Name',
			datafield: 'srEngine',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Roles',
			datafield: 'roleNames',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Input Audio Purge Period(in days)',
			datafield: 'inputAudioDays',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Converted Audio Purge Period(in days)',
			datafield: 'convertedAudioDays',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'SR Draft Purge Period(in days)',
			datafield: 'srDraftsDays',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'DB Records Purge Period(in days)',
			datafield: 'dbRecordsDays',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		}
	];

	source: any =
		{
			// url: 'http://localhost:8080/api/v2/jobs/',
			// type : 'POST',
			datafields: [
				{
					name: 'id',
					map: 'id'
				}, {
					name: 'username',
					map: 'username'
				}, {
					name: 'firstName',
					map: 'firstName'
				}, {
					name: 'lastName',
					map: 'lastName'
				}, {
					name: 'email',
					map: 'email'
				}, {
					name: 'providerId',
					map: 'providerId'
				}, {
					name: 'roleNames',
					map: 'roleNames'
				}, {
					name: 'inputAudioDays',
					map: 'inputAudioDays'
				}, {
					name: 'convertedAudioDays',
					map: 'convertedAudioDays'
				}, {
					name: 'srDraftsDays',
					map: 'srDraftsDays'
				}, {
					name: 'dbRecordsDays',
					map: 'dbRecordsDays'
				},{
					name:'srEngine',
					map: 'srEngineName'
				}
			],
			datatype: 'json',
			root: 'content',
			cache: false,
			sortcolumn: 'username',
    		sortdirection: 'asc'

		};

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		////  console.log('window resize :: ', event);
		this.height = event.target.innerHeight - 220;
		this.width = event.target.innerWidth - 500;
		this.user_creation_height = event.target.innerHeight - 45;
	}

	getHeight() {
		this.height = (window.screen.height - 390);
		window.dispatchEvent(new Event('resize'));
	}

	loadUsers() {
		this.aiAssist.getTabledata(this.users_url, this.mediaType, null).subscribe(res => {
			this.source.localdata = this.aiAssist.getJSONResponse(res).data.content;
			this.dataAdapter = new jqx.dataAdapter(this.source);
		});
	}

	refresh() {
		this.loadUsers();
	}

	openDialog(): void {
		this.getHeight();
		this.user = {}
		this.isEditMode = false;
		const dialogRef = this.dialog.open(UserCreationComponent, {
			width: this.width,
			height: this.user_creation_height,
			data: { isEditMode: this.isEditMode }
		});
		console.log('user before ', this.user)

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			this.user = result;
			console.log('user after ', this.user);
			if (this.user.id != undefined && this.user.id != "") {
				this.loadUsers();
			}
		});
	}

	editUserDialog(data): void {
		this.getHeight();
		this.user = {}
		this.isEditMode = true;
		const dialogRef = this.dialog.open(UserCreationComponent, {
			width: this.width,
			// height: this.user_creation_height - 30;
			data: { isEditMode: this.isEditMode, user: data }
		});
		console.log('user before ', this.user)

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			this.user = result;
			console.log('user after ', this.user);
			if (this.user.id != undefined && this.user.id != "") {
				this.loadUsers();
			}
		});
	}

	myGridOnContextMenu(): boolean {
		console.log('userGridOnContextMenu')
		return true;
	}
	selectedRow;
	myGridOnRowClick(event: any): void | boolean {
		this.selectedRow = '';
		console.log('userGridOnRowClick')
		this.userContextMenu.close();
		this.selectedRow = event.args.rowindex;
		console.log('selectedRow :: ', this.selectedRow);
		if (event.args.rightclick) {
			let scrollTop = window.scrollY;
			let scrollLeft = window.scrollX;
			this.userContextMenu.open(parseInt(event.args.originalEvent.clientX) + 5 + scrollLeft, parseInt(event.args.originalEvent.clientY) + 5 + scrollTop);
			return false;
		}
	}

	async myMenuOnItemClick(event: any) {
		let args = event.args;
		let dataRecord = this.usergrid.getrowdata(this.selectedRow);
		console.log('job id :: ', dataRecord);
		if (args.innerHTML == 'Delete') {
			this.openConfirmationDialog(dataRecord);
		} else if (args.innerHTML == 'Edit') {
			this.editUserDialog(dataRecord);
		}
	}

	openConfirmationDialog(userInfo) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				message: 'Are you sure want to delete(' + userInfo.username + ')?',
				buttonText: {
					ok: 'Yes',
					cancel: 'No'
				},
				isDelete: false,
				userInfo: userInfo
			}
		});

		dialogRef.afterClosed().subscribe((data: any) => {
			console.log('can delete the user record :: ', data.isDelete);
			if (data.isDelete) {
				const dataRecord = data.userInfo;
				this.aiAssist.deleteUser(dataRecord.id).subscribe(res => {
					this.loadUsers();
					this.notifierService.notify('success', dataRecord.username + ' deleted successfully');
				}, err => {
					var errMsg = 'Unable to delete ' + dataRecord.username + '. Please try after some time.'
					const errData = this.aiAssist.getJSONResponse(err).error;
					if (errData.message)
						errMsg = errData.message;
					this.notifierService.notify('error', errMsg);
				});
			}
		});
	}

}

export interface User {
	userName,
	firstName,
	lastName,
	email,
	password,
	confirmPassword,
	roles,
	providerId
}
