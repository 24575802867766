import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { element } from 'protractor';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AiAssistService } from 'src/app/services/ai-assist.service';

export interface TenantDetails{
  id: string;
  tenantName: string;
  environmentName: string;
}

@Component({
  selector: 'app-vocab',
  templateUrl: './vocab.component.html',
  styleUrls: ['./vocab.component.css']
})
export class VocabComponent implements OnInit {

  tenantControl = new FormControl('');
  tenantOptions: TenantDetails[];
  tenantFilteredOptions: Observable<TenantDetails[]>;

  orgControl = new FormControl('');
  orgOptions: any[];
  orgFilteredOptions: Observable<any[]>;

  deptControl = new FormControl('');
  deptOptions: any[] ;
  deptFilteredOptions: Observable<any[]>;

  jobTypeControl = new FormControl('');
  jobTypeOptions: any[];
  jobTypeFilteredOptions: Observable<any[]>;

  ruleControl = new FormControl('');
  ruleOptions: any[];
  ruleFilteredOptions: Observable<any[]>;

  instanceList = [{ key: 'Test', value: 'test' }, { key: 'USGOV', value: 'usgov' }, { key: 'Australia', value: 'australia' }, { key: 'Canada', value: 'canada' }, { key: 'UK', value: 'uk'}, { key: 'LAWENF', value: 'lawenf'}, { key: 'Mexico', value: 'mexico' }]
  
  instance= new FormControl('', [Validators.required]);

  availableItems = [];

  availableCheckedItems = [];

  availableSearchValue = '';

  selectedRule:any = {};

  selectedItems = [];
  selectedCheckedItems = [];

  searchValue = '';

  constructor(
    private aiAssist: AiAssistService) {
      
    }

    async onInstanceTypeChange(value){
      console.log('instance type changed from: '+ this.instance +", to: " + value);
      this.loadTenantList();
      
    }

    async onTenantChange(value){
      console.log('tenant changed from: '+ this.tenantControl.value +", to: " + value);
      const selectedTenant = this.tenantSelectedFilter(value, this.tenantOptions);
      console.log('selected tenant : ', selectedTenant);

      if(selectedTenant){
        this.aiAssist.orgList(selectedTenant[0].id).subscribe((data) => {
          this.orgOptions = this.aiAssist.getJSONResponse(data).data
          console.log('this.orgOptions : ' + this.orgOptions[0].displayName);
          this.orgFilteredOptions = this.orgControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.orgFilter(value || '', this.orgOptions)),
          );
        }, (error) => {})
      }
      this.fetchRules(this.instance.value, selectedTenant[0].tenantName, 'any', 'any', 'any');
    }

    loadTenantList(){
      const instanceType = this.instance.value;
      if(instanceType){
        this.aiAssist.tenantList(instanceType).subscribe((data) => {
          this.tenantOptions = this.aiAssist.getJSONResponse(data).data
          console.log('this.tenantOptions : ' + this.tenantOptions[0].tenantName);
          this.tenantFilteredOptions = this.tenantControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.tenantFilter(value || '', this.tenantOptions)),
          );
        }, (error) => {})
      }
      
    }

       
    async onOrgChange(value){
      console.log('Org changed from: '+ this.orgControl.value +", to: " + value);
      const selectedTenant = this.tenantSelectedFilter(this.tenantControl.value, this.tenantOptions);
      const selectedOrg = this.orgSelectedFilter(value, this.orgOptions);
      console.log('selected org : ', selectedOrg);

      if(selectedTenant && selectedOrg){
        this.aiAssist.deptList(selectedTenant[0].id, selectedOrg[0].externalId).subscribe((data) => {
          this.deptOptions = this.aiAssist.getJSONResponse(data).data
          console.log('this.deptOptions : ' + this.deptOptions[0].displayName);
          this.deptFilteredOptions = this.deptControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.orgFilter(value || '', this.deptOptions)),
          );
        }, (error) => {})
      }
      this.fetchRules(this.instance.value, selectedTenant[0].tenantName, selectedOrg[0].id, 'any', 'any');
    }

    async onDeptChange(value){
      console.log('Dept changed from: '+ this.deptControl.value +", to: " + value);
      const selectedTenant = this.tenantSelectedFilter(this.tenantControl.value, this.tenantOptions);
      const selectedOrg = this.orgSelectedFilter(this.orgControl.value, this.orgOptions);
      const selectedODept = this.orgSelectedFilter(value, this.deptOptions);
      console.log('selected dept : ', selectedODept);

      if(selectedTenant && selectedOrg && selectedODept){
        this.aiAssist.jobTypeList(selectedTenant[0].id, selectedOrg[0].externalId, selectedODept[0].externalId).subscribe((data) => {
          this.jobTypeOptions = this.aiAssist.getJSONResponse(data).data
          console.log('this.jobTypeOptions : ' + this.jobTypeOptions[0].displayName);
          this.jobTypeFilteredOptions = this.jobTypeControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.orgFilter(value || '', this.jobTypeOptions)),
          );
        }, (error) => {})
      }
      this.fetchRules(this.instance.value, selectedTenant[0].tenantName, selectedOrg[0].id, selectedODept[0].id, 'any');
    }

    async onJobTypeChange(value){
      console.log('Job Type changed from: '+ this.jobTypeControl.value +", to: " + value);
      const selectedTenant = this.tenantSelectedFilter(this.tenantControl.value, this.tenantOptions);
      const selectedOrg = this.orgSelectedFilter(this.orgControl.value, this.orgOptions);
      const selectedODept = this.orgSelectedFilter(this.deptControl.value, this.deptOptions);
      const selectedJobType = this.orgSelectedFilter(value, this.jobTypeOptions);
      console.log('selected job type : ', selectedJobType);
      this.fetchRules(this.instance.value, selectedTenant[0].tenantName, selectedOrg[0].id, selectedODept[0].id, selectedJobType[0].id);
      
    }

    fetchRules(instanceType, tenant, orgId, deptId, jobTypeId){
      console.log('fetching rules by instance : ' + instanceType + ', tenant : ' + tenant + ', org : '+ orgId + ', dept :' + deptId + ', job type : ' + jobTypeId)
      this.aiAssist.getRuleByMetadata(instanceType, tenant, orgId, deptId, jobTypeId).subscribe( 
        data => {
          this.selectedRule = this.aiAssist.getJSONResponse(data).data;
          this.selectedItems = this.selectedRule.vocabs;
          console.log('rule by metadata : ' + this.aiAssist.getJSONResponse(data).data);
          let availableItemsIds = [];
          this.availableItems.forEach(element => {
            availableItemsIds.push(element.id);
          });
          this.selectedItems.forEach(element => {
            this.availableItems = (this.availableItems.filter(item => item.id !== (element.id)))
          });
        }, 
        error => {});
    }

  onAvailableSearchChange(event: Event) {
    this.availableSearchValue = (event.target as HTMLInputElement).value;
  }

  isAvailableItemSelected(item: any) {
    return this.availableCheckedItems.findIndex(i => i.id === item.id) !== -1;
  }

  toggleAvailableItemSelection(item: any) {
    const index = this.availableItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.availableItems.splice(index, 1);
      item.checked = true;
      this.selectedItems.push(item);
      this.availableSearchValue = '';
    } 
  }

  onSearchChange(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
  }

  isItemSelected(item: any) {
    return this.selectedCheckedItems.findIndex(i => i.id === item.id) !== -1;
  }

  toggleItemSelection(item: any) {
    const index = this.selectedItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
      item.checked = false;
      this.availableItems.push(item);
      this.searchValue = ''
    } 
  }

  ngOnInit() {
    this.aiAssist.getAvailableVocabList().subscribe(
      data => {
        this.availableItems = this.aiAssist.getJSONResponse(data).data;
      }, 
      error => {})
    
  }

  private tenantFilter(value: string, options: any[]): any[] {
    const filterValue = value ? value.toLowerCase() : '';

    return options.filter(option => option.tenantName.toLowerCase().includes(filterValue));
  }

  private orgFilter(value: string, options: any[]): any[] {
    const filterValue = value ? value.toLowerCase() : '';

    return options.filter(option => option.displayName.toLowerCase().includes(filterValue));
  }

  private orgSelectedFilter(value: string, options: any[]): any[] {
    const filterValue = value ? value.toLowerCase() : '';

    return options.filter(option => option.displayName.toLowerCase() === (filterValue));
  }

  private tenantSelectedFilter(value: string, options: any[]): any {
    const filterValue = value ? value.toLowerCase() : '';

    return options.filter(option => option.tenantName.toLowerCase() === (filterValue));
  }

}
