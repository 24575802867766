import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AiAssistService } from '../services/ai-assist.service';

@Injectable({ providedIn: 'root' })
export class SysAdminGuard implements CanActivate {
    jwtHelper = new JwtHelperService();
    constructor(
        private router: Router,
        private aiAssist: AiAssistService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = localStorage.getItem("token");
        if(!this.jwtHelper.isTokenExpired(token)){
            const userDetails = this.jwtHelper.decodeToken(token);
           //  console.log("userDetails", userDetails)
            const result =  userDetails.authorities.includes('SYS_ADMIN');
            if(!result)
                this.router.navigate(['/forbidden']);
            return result;
        }

        return false;
    }
}