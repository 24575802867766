import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AiAssistService } from 'src/app/services/ai-assist.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  jobId;
  jobType;
  messages;
  height

  constructor(private route: ActivatedRoute,
    private aiAssist: AiAssistService) { }

  ngOnInit() {
    this.getParams();
  }

  @HostListener('window:resize', ['$event'])
	onResize(event) {
		////  console.log('window resize :: ', event);
		this.height = event.target.innerHeight - 220;
	}

	getHeight() {
		this.height = (window.screen.height - 390);
		window.dispatchEvent(new Event('resize'));
	}

  getParams() {
    this.route.params.subscribe(res => {
      const params = this.aiAssist.getJSONResponse(res);
     //  console.log('params :: ', params);
      this.jobId = params['jobId'];
      this.jobType = params['jobType'];
      this.aiAssist.getLogs(this.jobId).subscribe(res => {
        const data = this.aiAssist.getJSONResponse(res).data;
        this.messages = data.messages;
       //  console.log('get logs data :: ', data);
      }, err => {
       //  console.log('Error occured while getting logs for following job :: ', this.jobId);
      });
    });
  }

}
