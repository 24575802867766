import { Component, OnInit } from '@angular/core';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private aiAssist: AiAssistService) { }

  ngOnInit() {
    // this.getToken();
  }

  // async getToken(){
  //   const token = this.aiAssist.getJSONResponse(await this.aiAssist.getToken(environment.url).toPromise()).access_token;
  //   sessionStorage.setItem('token', token);
	// }

}
