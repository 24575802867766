import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ChangeDetectorRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { JobListComponent } from './components/job-list/job-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AngularJqwidgetsModule } from './angular-jqwidgets.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseAuthInterceptor } from './interceptor/base-auth-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserCreationComponent } from './components/user-creation/user-creation.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LogsComponent } from './components/logs/logs.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { VocabDictionaryComponent } from './components/vocab-dictionary/vocab-dictionary.component';
import { CreateVocabItemComponent } from './components/create-vocab-item/create-vocab-item.component';
import { RuleComponent } from './components/rule/rule.component';
import { RuleCreationComponent } from './components/rule-creation/rule-creation.component';
import { VocabComponent } from './components/vocab/vocab.component';
import { FilterPipe } from './filter.pipe';
import { PostProcessingComponent } from './components/post-processing/post-processing.component';
import { PpFilterPipe } from './pp-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    JobListComponent,
    DashboardComponent,
    LoginComponent,
    UserListComponent,
    UserCreationComponent,
    ForbiddenComponent,
    LogsComponent,
    ConfirmationDialogComponent,
    VocabDictionaryComponent,
    CreateVocabItemComponent,
    RuleComponent,
    RuleCreationComponent,
    VocabComponent,
    FilterPipe,
    PostProcessingComponent,
    PpFilterPipe
  ],
  imports: [
    AngularMaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularJqwidgetsModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule,
    
  ],
  entryComponents: [
    UserCreationComponent,
    ConfirmationDialogComponent,
    CreateVocabItemComponent,
    
  ],
  providers: [{provide : HTTP_INTERCEPTORS, useClass: BaseAuthInterceptor, multi: true},
    DatePipe],
    
  bootstrap: [AppComponent]
})
export class AppModule { }
