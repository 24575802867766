import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AiAssistService } from '../services/ai-assist.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class BaseAuthInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];
    jwtHelper = new JwtHelperService();

    constructor(private spinner: NgxSpinnerService,
        private aiAssistService: AiAssistService,
        private router: Router,
        private notifierService: NotifierService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            this.spinner.show();
        } else {
            this.spinner.hide();
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            if (this.jwtHelper.isTokenExpired(userToken)){
                this.aiAssistService.isLoggedIn = false;
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                // not logged in so redirect to login page with the return url
                this.notifierService.notify('Error',"Session exprired. Please login again.");
                this.router.navigate(['/login']);
                this.requests = [];
                return;
            }
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${userToken}`),
            });
        }
        this.requests.push(req);
       //  console.log("No of requests--->" + this.requests.length);
        this.spinner.show();
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}