import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatChipInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MyErrorStateMatcher } from '../user-creation/user-creation.component';
import { VocabDictionaryComponent } from '../vocab-dictionary/vocab-dictionary.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-create-vocab-item',
  templateUrl: './create-vocab-item.component.html',
  styleUrls: ['./create-vocab-item.component.css']
})
export class CreateVocabItemComponent implements OnInit, AfterViewInit {

  errorMessage;
  soundsLike = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  headerName = 'Create Vocab Item';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  createVocabForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  constructor(
    public dialogRef: MatDialogRef<VocabDictionaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private aiAssist: AiAssistService,
    private notifierService: NotifierService,) { }

  ngOnInit() {
    this.createVocabForm = this.formBuilder.group({
      vocabItem: new FormControl('', [Validators.required])
    });
  }
  ngAfterViewInit(){
    if(this.data.isEditMode){
      this.headerName = 'Edit Vocab Item';
      // for(var soundLike of this.data.vocabItem.soundsLikes){
      //   this.soundsLike.push(soundLike);
      // }
      this.createVocabForm.patchValue({
        vocabItem : this.data.vocabItem.vocabName
      })
    }
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  createVocab() {
    ////  console.log('create Vocab');
    if(this.createVocabForm.valid){
      const data = {
        vocabName : this.createVocabForm.controls.vocabItem.value,
        soundsLike: this.soundsLike
      }
      if(this.data.isEditMode){
        data['id'] = this.data.vocabItem.id;
      }
      this.aiAssist.saveOrUpdateVocabItem(data).subscribe( 
        res => {
          const data = this.aiAssist.getJSONResponse(res);
          this.dialogRef.close(data.data);
          if(data.status == 'SUCCESS'){
            let msg = data.data.vocabName + ' is created successfully.';
            if(this.data.isEditMode){
              msg = data.data.vocabName + ' is updated successfully.';
            }
            this.notifierService.notify('success', msg);
          }
        },
        err => {
          const response = this.aiAssist.getJSONResponse(err);
          ////  console.log('vocab creation response ', response);
          this.errorMessage = response.error.message;
        })

    }
  }

  errors(fieldName, error) {
    return this.createVocabForm.controls[fieldName].hasError(error);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.soundsLike.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(soundsLike: any): void {
    const index = this.soundsLike.indexOf(soundsLike);

    if (index >= 0) {
      this.soundsLike.splice(index, 1);
    }
  }

}
