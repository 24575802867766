import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobListComponent } from './components/job-list/job-list.component';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { SysAdminGuard } from './helpers/sysadmin.guard';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LogsComponent } from './components/logs/logs.component';
import { VocabDictionaryComponent } from './components/vocab-dictionary/vocab-dictionary.component';
import { RuleComponent } from './components/rule/rule.component';
import { RuleCreationComponent } from './components/rule-creation/rule-creation.component';
import { VocabComponent } from './components/vocab/vocab.component';
import { PostProcessingComponent } from './components/post-processing/post-processing.component';


const routes: Routes = [
  {path:'', redirectTo : 'transcode-job-list/transcode', pathMatch: 'full'},
  // {path:'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path:'job-list/:jobType', component: JobListComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'transcode-job-list/:jobType', component: JobListComponent, canActivate: [AuthGuard]},
  // {path: 'transcode-job-list', component: JobListComponent, canActivate: [AuthGuard]},
  {path: 'user-list', component: UserListComponent, canActivate: [AuthGuard,SysAdminGuard]},
  {path: 'rules', component: RuleComponent, canActivate: [AuthGuard,SysAdminGuard]},
  {path: 'rule/:type', component: RuleCreationComponent, canActivate: [AuthGuard,SysAdminGuard]},
  {path: 'rule/:type/:ruleId', component: RuleCreationComponent, canActivate: [AuthGuard,SysAdminGuard]},
  {path: 'forbidden', component: ForbiddenComponent, canActivate : [AuthGuard]},
  {path: 'logs/:jobType/:jobId', component: LogsComponent, canActivate : [AuthGuard]},
  {path: 'vocab-dictionary', component: VocabDictionaryComponent, canActivate: [AuthGuard, ]},
  {path: 'post-processing', component: PostProcessingComponent, canActivate: [AuthGuard, ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
