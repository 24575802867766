import { Router, ActivatedRoute } from '@angular/router';
// import { BackendServiceService } from './services/backend-service.service';
import { Component, ViewChild, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { AiAssistService } from './services/ai-assist.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { JobListComponent } from './components/job-list/job-list.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';

import { UserCreationComponent } from './components/user-creation/user-creation.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
// import { Idle } from 'idlejs/dist';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'aiAssist-admin';
  date = new Date();
  mobileQuery: MediaQueryList;
  opened = true;
  @ViewChild('snav', { static: false }) sidenav: MatSidenav;
  @ViewChild('grid', { static: false }) grid: jqxGridComponent;
  @ViewChild(JobListComponent, { static: false }) empty: JobListComponent;
  jwtHelper = new JwtHelperService();

  private _mobileQueryListener: () => void;
  private aiAssistService: AiAssistService;
  private route: Router;
  private version: String;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    aiAssistService: AiAssistService,
    route: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.version = environment.version;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.aiAssistService = aiAssistService;
    this.route = route;
    this.matIconRegistry.addSvgIcon(
      "transcode",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img_488443.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "viqlogo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/VIQ_White_Inline_ClearBG.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "vocabulary_dictionary_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Vocabulary_Dictionary.svg")
    );
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('token_name');
        if (token == undefined) {
          // Perform logout
          //Navigate to login/home
          this.logout();
        }
      }
    });
  }

  closeSideNav(){
    if(this.sidenav)
      this.sidenav.close();
  }

  isBiggerScreen() {

  }

  logout() {
    if (this.sidenav)
      this.sidenav.close();
    this.aiAssistService.isLoggedIn = false;
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.route.navigate(['/login']);
  }

  toggleMenu() {
    this.sidenav.toggle();
  }

  isShowable(requiredRoles: Array<String>): boolean {
    var isShowable = false;
    const userDetails = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    for (const role of requiredRoles) {
      if (userDetails.authorities.includes(role)) {
        isShowable = true;
      }
    }
    return isShowable;
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(UserCreationComponent, {
        width: '500px',
      //   height: this.user_creation_height,
      data: { isChangePasswordMode: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////  console.log('The change password dialog is closed');
    });
  }

}
