import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { RuleComponent } from '../rule/rule.component';
import { UserListComponent } from '../user-list/user-list.component';
import {COMMA, ENTER, P} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {Observable, range} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSelect } from '@angular/material/select';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-rule-creation',
  templateUrl: './rule-creation.component.html',
  styleUrls: ['./rule-creation.component.css']
})
export class RuleCreationComponent implements OnInit {

  errorMessage;
  isEditMode = false;
  creationRuleData;
  ruleId;
  ruleData;
  headerName = 'Create Rule';
  submitButtonName = 'Create';
  SINGLE = 'SINGLE';
  RANDOM = 'RANDOM';
  actionList = [this.SINGLE, this.RANDOM];
  instanceList = [
    { key: 'Dev', value: 'dev' },
    { key: 'Test', value: 'test' }, 
    { key: 'USGOV', value: 'usgov' }, 
    { key: 'Australia', value: 'australia' }, 
    { key: 'Canada', value: 'canada' }, 
    { key: 'UK', value: 'uk'}, 
    { key: 'LAWENF', value: 'lawenf'}, 
    { key: 'ORdigiNAL-AUS', value: 'ordiginal-aus'}, 
    { key: 'ORdigiNAL-UK', value: 'uk-ordiginal'}, 
    { key: 'Mexico', value: 'mexico' }]
  srConfigEnginesList = [];
  isPPSelectionChanged = false;
  isVocabSelectionChanged = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredSREngines: Observable<string[]>;
  srEngnies: string[] = [];
  engines: any = {};
  allSREngnies: string[] = ['AWS', 'SPEECHMATICS','REV', "WHISPER"];
  selectedEngine;
  selectedAction;

  @ViewChild('srEngineInput', { static: false }) srEngineInput: ElementRef<HTMLInputElement>;
  @ViewChild('operationTrigger', { static: false }) operationTrigger: MatAutocompleteTrigger;

  @ViewChild('instanceName', {static : true}) instanceName : MatSelect

  @ViewChild('actionName', {static : false}) actionName : MatSelect

  @ViewChild("InstanceIdField", { static: false }) instanceIdField;
 
  @ViewChild("chipList", { static: false }) chipList;
  @ViewChild('tenant', {static: false}) tenantAutoComplete: MatAutocomplete;
  form: FormGroup;
  engineForm: FormGroup;
  selectedIndex = 0;

  matcher = new MyErrorStateMatcher();
  engineFormCtrl= new FormControl('', [Validators.required]);
  action= new FormControl('', [Validators.required]);
  engineCtrl= new FormControl('', [Validators.required]);
  instance= new FormControl('', [Validators.required]);
  srConfigEngines= new FormControl('', [Validators.required]);
  any = 'any';

  selectedInstance;
  selectedTeant;
  selectedOrg;
  selectedDept;
  selectedJobType;

  tenantControl = new FormControl('', [Validators.required]);
  tenantOptions: any[];
  tenantFilteredOptions: Observable<any[]>;

  orgControl = new FormControl('');
  orgOptions: any[];
  orgFilteredOptions: Observable<any[]>;

  deptControl = new FormControl('');
  deptOptions: any[] ;
  deptFilteredOptions: Observable<any[]>;

  jobTypeControl = new FormControl('');
  jobTypeOptions: any[];
  jobTypeFilteredOptions: Observable<any[]>;

  ruleControl = new FormControl('');
  ruleOptions: any[];
  ruleFilteredOptions: Observable<any[]>;

  availableItems = [];
  availablePPItems = [];

  availableSearchValue = '';
  availablePPSearchValue = '';

  selectedRule:any = {};

  selectedItems = [];
  selectedPPItems = [];

  searchValue = '';
  selectedPPSearchValue = '';
  tenantList;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private aiAssist: AiAssistService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private spinner: NgxSpinnerService) {

      this.filteredSREngines = this.engineCtrl.valueChanges.pipe(
        startWith(null),
        map((engine: string | null) => (engine ? this._filter(engine) : this.allSREngnies)),
      );

     }

     async onInstanceTypeChange(value): Promise<any>{
      this.tenantControl.setValue('')
      this.orgControl.setValue('');
      this.deptControl.setValue('');
      this.jobTypeControl.setValue('');
      await this.loadTenantList();
      return "data"
      
    }

    reset(){
      this.loadExistingRuleData();
      this.srEngnies = [];
      this.selectedItems = [];
      this.selectedPPItems = [];
      this.loadVocabulary();
      this.loadPostProcessing();
    }

    async onTenantChange(value){
      const selectedTenant = this.tenantSelectedFilter(value, this.tenantOptions);
      this.orgControl.setValue('');
      this.deptControl.setValue('');
      this.jobTypeControl.setValue('');
      if(selectedTenant){
        const orgList = await this.aiAssist.orgList(selectedTenant[0].id).toPromise();
        this.orgOptions = orgList["data"];
          this.orgFilteredOptions = this.orgControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.orgFilter(value || '', this.orgOptions)),
          );
      }
    }

    async loadTenantList(): Promise<any>{
      const instanceType = this.instance.value;
      if (instanceType) {
        let tenantList = await this.aiAssist.tenantList(instanceType).toPromise();
        this.tenantOptions = tenantList["data"];
        this.tenantFilteredOptions = this.tenantControl.valueChanges.pipe(
          startWith(''),
          map((value: string) => this.tenantFilter(value || '', this.tenantOptions)),
        );
      }
      return "data"
    }

       
    async onOrgChange(value){
      const selectedTenant = this.tenantSelectedFilter(this.tenantControl.value, this.tenantOptions);
      const selectedOrg = this.orgSelectedFilter(value, this.orgOptions);
      this.deptControl.setValue('');
      this.jobTypeControl.setValue('');
      if(selectedTenant && selectedOrg){
        const deptList = await this.aiAssist.deptList(selectedTenant[0].id, selectedOrg[0].externalId).toPromise();
        this.deptOptions = deptList["data"]
          this.deptFilteredOptions = this.deptControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.orgFilter(value || '', this.deptOptions)),
          );
      }
    }

    async onDeptChange(value){
      const selectedTenant = this.tenantSelectedFilter(this.tenantControl.value, this.tenantOptions);
      const selectedOrg = this.orgSelectedFilter(this.orgControl.value, this.orgOptions);
      const selectedODept = this.orgSelectedFilter(value, this.deptOptions);
      this.jobTypeControl.setValue('');
      if(selectedTenant && selectedOrg && selectedODept){
        const jobTypeList = await this.aiAssist.jobTypeList(selectedTenant[0].id, selectedOrg[0].externalId, selectedODept[0].externalId).toPromise();
        this.jobTypeOptions = jobTypeList["data"]
          this.jobTypeFilteredOptions = this.jobTypeControl.valueChanges.pipe(
            startWith(''),
            //map(value => this.orgFilter(value || '', this.jobTypeOptions)),
            map((value: string) => this.orgFilter(value || '', this.orgOptions)),

          );
      }
    }

    async onJobTypeChange(value){
      
      
    }
    

    fetchRules(instanceType, tenantName, selectedOrg, selectedDept, selectedJobTypeId){
      this.selectedInstance = instanceType;
      this.selectedTeant = tenantName;
      this.selectedOrg = selectedOrg;
      this.selectedDept = selectedDept;
      this.selectedJobType = selectedJobTypeId;
      console.log('fetching rules by instance : ' + instanceType + ', tenant : ' + tenantName + ', org : '+ this.selectedOrg + ', dept :' + this.selectedDept + ', job type : ' + this.selectedJobType)
      this.aiAssist.getRuleByMetadata(this.selectedInstance, this.selectedTeant, this.selectedOrg, this.selectedDept, this.selectedJobType).subscribe( 
        data => {
          this.selectedRule = this.aiAssist.getJSONResponse(data).data;
          if(this.selectedRule == null || (this.isEditMode && this.ruleData.data.ruleId === this.selectedRule.ruleId)){
            this.selectedIndex = 1;
            setTimeout(() => {
              this.actionName.focus();
            }, 300);
          }else{
            this.errorMessage = 'We already have a rule with the same metadata, please try with different metadata';
            return;
          }
        }, 
        error => {
          
        });
    }

    private tenantFilter(value: string, options: any[]): any[] {
      const filterValue = value ? value.toLowerCase() : '';
  
      return options ? options.filter(option => option.tenantName.toLowerCase().includes(filterValue)) :[];
    }
  
    private orgFilter(value: string, options: any[]): any[] {
      const filterValue = value ? value.toLowerCase() : '';
  
      return options ? options.filter(option => option.displayName.toLowerCase().includes(filterValue)) : [];
    }
  
    private orgSelectedFilter(value: string, options: any[]): any[] {
      const filterValue = value ? value.toLowerCase() : '';
  
      return options ? options.filter(option => option.displayName.toLowerCase() === (filterValue)): [];
    }
  
    private tenantSelectedFilter(value: string, options: any[]): any {
      const filterValue = value ? value.toLowerCase() : '';
  
      return options ? options.filter(option => option.tenantName.toLowerCase() === (filterValue)) : [];
    }
  
  enableAndDisableProbability(){
    if(this.selectedAction == this.SINGLE){
      this.engineForm.controls.probability.setValue(100);
      this.engineForm.controls.probability.disable({onlySelf : true});
    }else{
      this.engineForm.controls.probability.setValue('');
      this.engineForm.controls.probability.enable({onlySelf : true});
    }
  }
  
  editEngine(event, engine){
    this.clear();
    this.selectedEngine = engine;
    const tempEngine = this.engines[engine];
   //  console.log('action :: '+ this.selectedAction);
    this.enableAndDisableProbability();
    if(tempEngine){
      this.engineForm.controls.speakerLables.setValue(tempEngine.maxSpeakerLabels);
      this.engineForm.controls.languageModel.setValue(tempEngine.modelName);
      if(this.selectedAction != this.SINGLE){
        this.engineForm.controls.probability.setValue(tempEngine.probability);
      }
      this.engineForm.controls.vocabName.setValue(tempEngine.vocabName);
    }
    this.addEngine(true);
  }

  removeEngine(enigne){
    delete this.engines[enigne];
  }

  clear(){
    this.engineForm.reset();
  }

  addEngine(isFirstTime){
    if(this.selectedEngine){
      var config = this.engines[this.selectedEngine];
      const speakerLables = this.engineForm.controls.speakerLables.value;
      const languageModel = this.engineForm.controls.languageModel.value;
      const probability = this.engineForm.controls.probability.value;
      const vocabName = this.engineForm.controls.vocabName.value;
      if(config){
        config.name = this.selectedEngine;
        config.description = this.selectedEngine;
        config.maxSpeakerLabels = speakerLables;
        config.modelName = languageModel;
        config.probability = probability;
        config.vocabName = vocabName;
        config.deleted = false;
      }else{
      this.engines[this.selectedEngine] = {
        'name': this.selectedEngine,
        'description' : this.selectedEngine,
        'maxSpeakerLabels' : speakerLables,
        'modelName' : languageModel,
        'probability' : probability,
        'vocabName' : vocabName,
        'deleted' : false,
        'allowHallucinations': false,
        'removeDisfluencies': false
      }
    }
    // console.log(this.engines);
      if(!isFirstTime){
        this.clear();
        this.selectedEngine = undefined;
      }
    }

  }

  actionChangeEvent(action){
    this.selectedAction = action;
    this.enableAndDisableProbability();
    // When changed the action, clearing the sr engine selection.
    // console.log(this.srEngnies)
    for(let enigne of ["AWS","SPEECHMATICS","REV", "WHISPER"]){
      // console.log(enigne)
      this.remove(enigne);
    }
  }

  onNoClick(): void {
    this.errorMessage = undefined;
    this.form.reset();
    this.instance.reset();
    this.action.reset();
    this.srEngnies = [];
    this.clear();
    this.selectedItems = [];
    this.selectedPPItems = [];
    this.loadVocabulary();
    this.loadPostProcessing();
  }

  getParams() {
    this.route.params.subscribe(res => {
      const params = this.aiAssist.getJSONResponse(res);
     //  console.log('params :: ', params);
      this.isEditMode = params['type'] == 'edit' ? true : false;
      if(this.isEditMode){
        this.ruleId = params['ruleId'];
      }
      }, err => {
       //  console.log('Error occured while getting loading the rule page');
    });
  }

  ngOnInit() {
    // this.loadSRConfigEngines()
    this.getParams();
    this.form = this.formBuilder.group({
      jobTypeId: new FormControl(''),
      tenantId: new FormControl('', Validators.required),
      organizationId: new FormControl(''),
      departmentId: new FormControl(''),
     
    });

    this.engineForm = this.formBuilder.group({
      speakerLables: new FormControl(''),
      languageModel: new FormControl(''),
      probability: new FormControl(''),
      vocabName: new FormControl(''),
    })

    // this.loadSRConfigEngines();
  }

  loadSRConfigEngines(){
    this.aiAssist.getSRConfigEngines().toPromise();
  }
  async loadExistingRuleData(){
    if (this.isEditMode) {
      //  console.log('EDIT');
       this.ruleData = await this.aiAssist.getRule(this.ruleId).toPromise();
       console.log('ruleData ',this.ruleData);
 
       this.headerName = 'Edit Rule';
       this.submitButtonName = 'Update';
       this.instance.setValue(this.ruleData.data.instanceId);
       this.form.patchValue({
         tenantId: this.ruleData.data.tenantId,
         organizationId: this.ruleData.data.organizationId,
         departmentId: this.ruleData.data.departmentId,
         jobTypeId: this.ruleData.data.jobTypeId
       });
       this.action.setValue(this.ruleData.data.action == 'USE' ? this.SINGLE : this.RANDOM);
       this.selectedAction = this.action.value;
       for(let index in this.ruleData.data.configurations){
         const config = this.ruleData.data.configurations[index];
         config.probability = config.probability * 100;
         this.srEngnies.push(config.name);
         this.engines[config.name] = config;
       }
     }
     setTimeout(() => {
         this.instanceName.focus();
     }, 500);
  }

  ngAfterViewInit() {
    this.loadExistingRuleData()

  }
  errors(fieldName, error) {
    return this.form.controls[fieldName].hasError(error);
  }

  engineErrors(fieldName, error) {
    return this.engineForm.controls[fieldName].hasError(error);
  }

  get f() { return this.form.controls; }

  async validateRule(ruleDto){
    await this.aiAssist.validateRule(ruleDto).toPromise();
  }
  async nextPageValidation() {
    var isValidForm = true;
    this.errorMessage = undefined;
    if (this.selectedIndex === 0) {
      if(this.instance.invalid){
        this.instance.markAllAsTouched();
        this.instance.markAsDirty({ onlySelf: true });
        isValidForm = false;
      }
  
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.form.markAsDirty({ onlySelf: true });
        isValidForm = false;
      }
      
      if(isValidForm){
        const tenantName = this.form.controls.tenantId.value;
        const ordId = this.form.controls.organizationId.value;
        const deptId = this.form.controls.departmentId.value;
        const jobTypeId = this.form.controls.jobTypeId.value;
        this.fetchRules(this.instance.value.toLowerCase(),
          tenantName ? tenantName.trim() == '' ? this.any : tenantName.trim() : this.any ,
          ordId ? ordId : this.any,
          deptId ? deptId : this.any,
          jobTypeId ? jobTypeId : this.any);
    }
    }else if(this.selectedIndex === 1){
      this.srConfigValidation();
    }else if(this.selectedIndex === 2){
      this.loadPostProcessing();
      this.selectedIndex === this.selectedIndex++;
    }
    else{
      this.selectedIndex === this.selectedIndex++;
    }
  }

  onAvailableSearchChange(event: Event) {
    this.availableSearchValue = (event.target as HTMLInputElement).value;
    console.log('this.availableSearchValue : ' ,this.availableSearchValue);
  }

  toggleAvailableItemSelection(item: any) {
    const index = this.availableItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.availableItems.splice(index, 1);
      item.checked = true;
      this.selectedItems.push(item);
      this.isVocabSelectionChanged = true;
      this.availableSearchValue = '';
    } 
  }

  onSearchChange(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
  }

  toggleItemSelection(item: any) {
    const index = this.selectedItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
      item.checked = false;
      this.availableItems.push(item);
      this.isVocabSelectionChanged = true;
      this.searchValue = ''
    } 
  }

  onAvailablePPSearchChange(event: Event) {
    this.availablePPSearchValue = (event.target as HTMLInputElement).value;
    console.log('this.availablePPSearchValue : ' ,this.availablePPSearchValue);
  }

  toggleAvailablePPItemSelection(item: any) {
    const index = this.availablePPItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.availablePPItems.splice(index, 1);
      item.checked = true;
      this.selectedPPItems.push(item);
      this.isPPSelectionChanged = true;
      this.availablePPSearchValue = '';
    } 
  }

  onSelectedPPItemSearchChange(event: Event) {
    this.selectedPPSearchValue = (event.target as HTMLInputElement).value;
  }

  toggleSelectedPPItemSelection(item: any) {
    const index = this.selectedPPItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.selectedPPItems.splice(index, 1);
      item.checked = false;
      this.availablePPItems.push(item);
      this.isPPSelectionChanged = true;
      this.searchValue = ''
    } 
  }


  srConfigValidation() {
    var isValidForm = true;
    this.errorMessage = undefined;

    if(this.action.invalid){
      this.action.markAllAsTouched();
      this.action.markAsDirty({ onlySelf: true });
      isValidForm = false;
    }
    
    if(this.chipList.chips.length == 0){
      this.chipList.errorState = true;

      isValidForm = false;
    }else{
      this.chipList.errorState = false;
    }
    
    if(!isValidForm){
      return;
    }
    const enginesLength = this.chipList.chips.length;
    const tempAction = this.action.value;
    if((enginesLength == 1 && tempAction != this.SINGLE) || (enginesLength > 1 && tempAction == this.SINGLE)){
      this.errorMessage = 'Invalid Action and SR Engines selection. If you want to select multiple SR Engines, please select the Action field value as RANDOM, otherwise select SINGLE';
      return;
    }
    const configurations = []
    // if(tempAction != this.SINGLE){
    let count = 0;
    for(const engine in this.engines){
      if(!this.engines[engine].deleted)
      // console.log('engine name :: ' + engine)
      // console.log('engine count :: ' + this.engines[engine].probability) 
        count = count + this.engines[engine].probability;
        // let config = this.engines[engine];
        // config.probability = config.probability/100;
        configurations.push(this.engines[engine]);
    }
    if(this.selectedAction == this.SINGLE){
      count = 100;
      configurations[0].probability = 100;
    }
    // console.log(count)
    if(count != 100){
      this.errorMessage = 'Sum of all Selected SR Engines probability should be 100%';
      return;
    }
    this.loadVocabulary();
    this.selectedIndex = 2;
    // }
    for(const index in configurations){
      configurations[index].probability = configurations[index].probability/100;
    }
    const tenantName = this.form.controls.tenantId.value;
    const ordId = this.form.controls.organizationId.value;
    const deptId = this.form.controls.departmentId.value;
    const jobTypeId = this.form.controls.jobTypeId.value;
    this.creationRuleData = {
      instanceId: this.instance.value.toLowerCase(),
      tenantId: tenantName ? tenantName.trim() == '' ? this.any : tenantName.trim() : this.any ,
      organizationId:  ordId ? ordId : this.any,
      departmentId: deptId ? deptId : this.any,
      jobTypeId:  jobTypeId ? jobTypeId : this.any,
      action: this.action.value == this.SINGLE ? 'USE' : 'RANDOM',
      configurations: configurations
    }
     //  console.log(data);
    //this.createRuleInDB(data);
  }

  loadVocabulary(){
    // if(this.isVocabSelectionChanged){
    //   return;
    // }
    this.aiAssist.getAvailableVocabList().subscribe(
      data => {
        this.availableItems = this.aiAssist.getJSONResponse(data).data;
        if (this.isEditMode && this.ruleData && this.ruleData != null) {
          this.ruleData.data.vocabs.forEach(element => {
            const index = this.selectedItems.findIndex(i => i.id === element.id);
            if (index == -1) {
              this.selectedItems.push(element);
            } 
          });
        }
        this.selectedItems.forEach(element => {
          this.availableItems = (this.availableItems.filter(item => item.id !== (element.id)))
        });
      }, 
      error => {})
  }

  loadPostProcessing(){
    // if(this.isPPSelectionChanged){
    //   return;
    // }
    const payload = {filters: [], sortdatafield : 'word', sortorder : 'asc', pagesize: 10000};
    this.aiAssist.getAvailablePPList(payload).subscribe( data =>{
      this.availablePPItems = this.aiAssist.getJSONResponse(data).data.content;
      if (this.isEditMode && this.ruleData && this.ruleData != null) {
        this.ruleData.data.postProcessingDetails.forEach(element => {
          const index = this.selectedPPItems.findIndex(i => i.id === element.id);
          if (index == -1) {
            this.selectedPPItems.push(element);
          } 
        });
      }
      this.selectedPPItems.forEach(element => {
        this.availablePPItems = (this.availablePPItems.filter(item => item.id !== (element.id)))
      });
    });
  }

  createRule(){
    this.creationRuleData['vocabs'] = this.selectedItems;
    this.creationRuleData['postProcessingDetails'] = this.selectedPPItems;
    console.log('create rule obj : ', this.creationRuleData)
    this.createRuleInDB(this.creationRuleData);
  }

  createRuleInDB(data: any) {
    if (this.isEditMode) {
      data.ruleId = this.ruleData.data.ruleId;
    }
    var userSuccessMsg = '';
    this.aiAssist.createRule(data).subscribe(res => {
      const response = this.aiAssist.getJSONResponse(res);
      // this.dialogRef.close(response.data);
      userSuccessMsg = 'Rule created successfully';
      if (this.isEditMode) {
        userSuccessMsg = 'Rule updated successfully';
      }
      this.notifierService.notify('success', userSuccessMsg);
      setTimeout(() => {
        this.router.navigate(['/rules']);
    }, 1000);
    }, err => {
      const response = this.aiAssist.getJSONResponse(err);
      this.errorMessage = response.message;
    });
  }

  makeFormDirty(form : FormGroup){
    form.markAllAsTouched();
    form.markAsDirty({ onlySelf: true });
  }

  isFormValid() {
    return this.form.invalid && this.instance.invalid;
  }

  addChip(value){
    this.chipList.errorState = false;
    if(!(['AWS', 'SPEECHMATICS', 'REV', "WHISPER"].includes(value))){
      this.errorMessage = value + ' is not available';
      return;
    }
    if (value && !this.srEngnies.includes(value)) {
      this.srEngnies.push(value);
    }

    this.srEngineInput.nativeElement.value = '';
    this.editEngine(null, value);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.addChip(value);
  }

  remove(engine: string): void {
    const index = this.srEngnies.indexOf(engine);

    if (index >= 0) {
      this.srEngnies.splice(index, 1);
    }
    // delete this.engines[engine];
    if(this.engines[engine]){
      this.engines[engine].deleted = true;
    }
    this.selectedEngine = undefined;
    this.engineCtrl.setValue('');
    this.srEngineInput.nativeElement.click()
    this.openAutocompletePanel();
  }

  openAutocompletePanel(){
    this.operationTrigger.openPanel();
   
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    this.addChip(value);
    this.openAutocompletePanel();
  }

  private _filter(value: string): string[] {
    if(value){
      const filterValue = value.toLowerCase();
      return this.allSREngnies.filter(engine => engine.toLowerCase().includes(filterValue));
    }
    else
      return this.allSREngnies;
  }


  
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}