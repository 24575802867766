import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AiAssistService {

  isLoggedIn = false;
  jwtHelper = new JwtHelperService();
  userDetails;

  constructor(private http: HttpClient) { 
    this.loadUserDetails();
  }

  public getToken(url, username, password) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa('testclientid:testsecret')
      })
    };
    let data = new FormData();

    // if(url == 'https://australia.aiassist.ai'){
    //   data.append("username","spuvvalla");
    //   data.append("password","password");
    // }else{
    data.append("username", username);
    data.append("password", password);
    // }

    data.append("grant_type", "password");



    return this.http.post(url + "/oauth/token", data, httpOptions);
  }

  public getHeaderOptions(token) {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer  ' + token
      })
    };
    return httpHeaders;
  }

  public getJSONResponse(data) {
    let output = JSON.parse(JSON.stringify(data));
    return output;
  }

  getTabledata(url, mediaType, data) {
    if (mediaType == 'POST')
      return this.http.post(url, data);
    else
      return this.http.get(url);
  }

  getSpeechResult(url, data) {
    return this.http.post(url, data);
  }

  getRoles() {
    return this.http.post(environment.url + '/api/v1/role/list', null);
  }

  getProviders() {
    return this.http.post(environment.url + '/api/v1/provider/list', null);
  }

  createUser(data) {
    return this.http.post(environment.url + '/api/v2/user/save', data);
  }

  deleteUser(id) {
    return this.http.delete(environment.url + '/api/v2/user/' + id);
  }

  loadUserDetails() : boolean{
    this.userDetails = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    return this.userDetails;
  }

  changeUserPassword(data) {
    return this.http.post(environment.url + '/api/v2/user/changepassword', data);
  }

  getLogs(jobId){
    return this.http.get(environment.url + '/api/v2/job/'+jobId + '/logs');
  }

  requestForLogs(data){
    return this.http.post(environment.url + '/api/v2/job/logs', data);
  }
  
  getTranscodeLogs(jobId){
    return this.http.get(environment.url + '/api/v2/transcode/'+jobId + '/logs');
  }

  requestForTranscodeLogs(data){
    return this.http.post(environment.url + '/api/v2/transcode/logs', data);
  }

  getVocabList(data){
    return this.http.get(environment.url + '/api/v2/vocab/list');
  }

  saveOrUpdateVocabItem(data){
    return this.http.post(environment.url + '/api/v2/vocab', data);
  }

  deleteVocab(data){
    return this.http.post(environment.url + '/api/v2/vocab/delete', data);
  }

  checkVocab(id){
    return this.http.get(environment.url + '/api/v2/vocab/check/'+ id);
  }

  getSRConfigEngines(){
    return this.http.get(environment.url + '/api/v2/sr-engine-configurations');
  }

  createRule(data) {
    if(data.ruleId)
      return this.updateRule(data);
    return this.http.post(environment.url + '/api/v2/rule', data);
  }

  updateRule(data) {
    return this.http.post(environment.url + '/api/v2/rule/'+data.ruleId, data);
  }

  getRule(id) {
    return this.http.get(environment.url + '/api/v2/rule/'+id);
  }

  deleteRule(id) {
    return this.http.delete(environment.url + '/api/v2/rule/'+id);
  }

  deleteConfig(id){
    return this.http.delete(environment.url + '/api/v2/sr-engine-configurations/' + id)
  }

  validateRule(data){
    return this.http.post(environment.url + '/api/v2/rule/validate', data);
  }

  tenantList(instanceType){
    return this.http.get(environment.url + '/api/v2/nsw-entities?instanceType='+instanceType);
  }

  orgList(tenantId){
    return this.http.get(environment.url + '/api/v2/nsw-entities/'+tenantId);
  }

  deptList(tenantId, orgExtId){
    return this.http.get(environment.url + '/api/v2/nsw-entities/'+tenantId+"/"+orgExtId);
  }
  jobTypeList(tenantId, orgExtId, deptExtId){
    return this.http.get(environment.url + '/api/v2/nsw-entities/'+tenantId+"/"+orgExtId +"/"+deptExtId);
  }

  getRuleByMetadata(instance, tenant, orgId, deptId, jobTypeId){
    return this.http.get(environment.url + '/api/v2/rule-by-metadata?instance='+instance+'&tenant=' +tenant+'&orgId='+orgId +'&deptId='+deptId+'&jobTypeId='+jobTypeId);
  }

  getAvailableVocabList(){
    return this.http.get(environment.url + '/api/v2/vocab/list');
  }

  getAvailablePPList(data){
    return this.http.post(environment.url + '/api/v2/pp/list', data);
  }

  createOrUpdatePP(payload){
    return this.http.post(environment.url + '/api/v2/pp', payload);
  }

  deletePP(ppId){
    return this.http.delete(environment.url + '/api/v2/pp/' + ppId);
  }

  checkPP(ppId){
    return this.http.get(environment.url + '/api/v2/pp/check/' + ppId);
  }
}
