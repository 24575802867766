import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { environment } from 'src/environments/environment';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { NotifierService } from 'angular-notifier';


@Component({
	selector: 'app-job-list',
	templateUrl: './job-list.component.html',
	styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit, AfterContentChecked {

	constructor(private aiAssist: AiAssistService,
		private datePipe: DatePipe,
		private route: ActivatedRoute,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		private notifier: NotifierService) { }

	@ViewChild('grid', { static: false }) grid: jqxGridComponent;
	@ViewChild('myMenu', { static: false }) myMenu: jqxMenuComponent;
	@ViewChild('myWindow', { static: false }) myWindow: jqxWindowComponent;

	@ViewChild('myDateDropDownList', { static: false }) myDateDropDownList: jqxDropDownListComponent;

	REQUEST = "Request";
	RUNNING = "Running";
	COMPLETE = "Open logs";

	pagenum;
	pagesize;
	sortorder;
	sortfield;
	searchfilters = [];

	searchJobDto: any;

	customSearch = false;
	selectedStartDate: any;
	selectedEndDate: any;
	transcode_job_type = 'transcode';
	speech_job_type = 'speech';
	users_job_type = 'users'
	mediaType = 'POST';

	@Input() jobType = 'transcode';
	@Input() page_title = 'Transcode Jobs List';
	transcode_url = environment.url + '/api/v2/transcode/jobs';
	speech_url = environment.url + '/api/v2/jobs';
	url;
	height;
	counter: number = 1;
	tooltipCounter: number = 100000;
	templeteName;
	columnWidth;
	tooltiprenderer = (element: any): void => {
		let id = `toolTipContainer${this.tooltipCounter}`;
		element[0].id = id;
		////  console.log('header element :: ', element[0]);
		let content = element[0].innerHTML;
		setTimeout(_ => jqwidgets.createInstance(`#${id}`, 'jqxTooltip', { position: 'mouse', content: content }))
		this.tooltipCounter++;
	}

	@Input() columns: any[] = [
		{
			text: 'aiAssist ID',
			datafield: 'id',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
		}, {
			text: 'External ID',
			datafield: 'referenceId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
		}, {
			text: 'Job Processing Start Time',
			datafield: 'startTime',
			width: this.getCellWidth(),
			filtertype: 'range', cellsformat: 'MMM-dd-yyyy HH:mm:ss tt zzz',
			cellsalign: 'center',
			align: 'center',
		},
		 {
			text: 'End Date',
			datafield: 'endTime',
			width: this.getCellWidth(),
			filtertype: 'range', cellsformat: 'MMM-dd-yyyy HH:mm:ss tt zzz',
			cellsalign: 'center',
			align: 'center',
			filterable: false
		},
		 {
			text: 'File ID',
			datafield: 'fileId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
		}, 
		// {
		// 	text: 'Provider ID',
		// 	datafield: 'providerId',
		// 	width: this.getCellWidth(),
		// 	cellsalign: 'center',
		// 	align: 'center',
		// },
		 {
			text: 'State',
			datafield: 'status',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
		}, {
			text: 'Status Message',
			datafield: 'statusMessage',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
		}
	];

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		////  console.log('window resize :: ', event);
		this.height = event.target.innerHeight - 220;
	}

	getHeight() {
		this.height = (window.screen.height - 390);
		window.dispatchEvent(new Event('resize'));
	}

	getCellWidth() {
		var number_of_columns = 9;
		if (this.jobType == this.speech_job_type) {
			number_of_columns = 10;
		}
		return (100 / number_of_columns).toFixed(2) + '%';
	}

	@Input() source: any =
		{
			// url: 'http://localhost:8080/api/v2/jobs/',
			// type : 'POST',
			datafields: [
				{
					name: 'id',
					map: 'id'
				}, {
					name: 'referenceId',
					map: 'referenceId'
				}, {
					name: 'startTime',
					map: 'startTime',
					type: 'date'
				}, {
					name: 'endTime',
					map: 'endTime',
					type: 'date'
				}, {
					name: 'fileId',
					map: 'fileId'
				}, {
					name: 'providerId',
					map: 'providerId'
				}, {
					name: 'status',
					map: 'status'
				}, {
					name: 'statusMessage',
					map: 'statusMessage'
				}, {
					name: 'preset',
					map: 'preset'
				}, {
					name: 'logStatus',
					map: 'logStatus'
				},{
					name: 'userBasedEngine',
					map: 'userBasedEngine'
				},{
					name: 'ruleBasedEngine',
					map: 'ruleBasedEngine'
				}
			],
			datatype: 'json',
			root: 'content',
			cache: false,
			sort: () => {
				// update the grid and send a request to the server.
				this.grid.updatebounddata('sort');
			},
			filter: () => {
				// update the grid and send a request to the server.
				this.grid.updatebounddata('filter');
			},
			// beforeprocessing: (data: any) => {
			// 	console.log('before source :: ', this.source);
			// 	this.source.totalrecords = data.data.totalElements;
			// 	console.log('after source :: ', this.source);
			// }

		};

	dataAdapter: any;// = new jqx.dataAdapter(this.source);

	rendergridrows = (params: any): any => {
		return params.data;
	}

	ngOnInit() {
		this.getHeight();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getParams() {
		console.log('getParams()');
		this.route.params.subscribe(res => {
			const params = this.aiAssist.getJSONResponse(res);
			console.log('params :: ', params);
			this.jobType = params['jobType'];
			// if (!this.jobType) {
			// 	this.jobType = this.transcode_job_type;
			// }
		});
	}

	ngAfterViewInit() {
		document.addEventListener('contextmenu', event => event.preventDefault());
		this.loadJobs();
		this.grid.columnsresize(true);
		this.grid.enabletooltips(true);
		
	}

	addWeekfilter(){
		let currentdate = new Date();
		let weekdate = new Date();
		weekdate.setDate(currentdate.getDate() - 6);
		let dateFilterGroup = new jqx.filter();
		let filter1 = dateFilterGroup.createfilter('datefilter',this.datePipe.transform(weekdate, 'MMM-dd-yyyy 00:00:00'),'GREATER_THAN_EQUAL');
		let filter2 = dateFilterGroup.createfilter('datefilter',this.datePipe.transform(currentdate, 'MMM-dd-yyyy 23:59:59'),'LESS_THAN_EQUAL');
		dateFilterGroup.addfilter(1, filter1);
		dateFilterGroup.addfilter(1, filter2);
		this.grid.addfilter('startTime', dateFilterGroup, false);
		this.grid.applyfilters();
	}

	refresh() {
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	async loadJobs() {
		this.addWeekfilter();
		await this.getParams();
		if (this.jobType == this.speech_job_type) {
			this.page_title = 'Speech Jobs List';
			this.url = this.speech_url;
			this.loadSpeechJobs();
			this.columns.push({
				text: 'User Based SR engine',
				datafield: 'userBasedEngine',
				width: this.getCellWidth(),
				filterable: false,
				cellsalign: 'center',
				align: 'center',
			},{
				text: 'Rule Based SR engine',
				datafield: 'ruleBasedEngine',
				width: this.getCellWidth(),
				filterable: false,
				cellsalign: 'center',
				align: 'center',
			},
				{
					text: 'Log Status', datafield: 'logStatus', width: this.getCellWidth(),
					filterable: false,
					align: 'center',
					createwidget: (row: number, column: any, value: string, htmlElement: HTMLElement): void => {
						if (value.toLowerCase() == 'not available') {
							value = this.REQUEST;
							this.templeteName = 'primary';
						} else if (value.toLowerCase() == 'running') {
							value = this.RUNNING;
							this.templeteName = 'warning';
						} else if (value.toLowerCase() == 'complete') {
							value = this.COMPLETE;
							this.templeteName = 'success';
						} else {
							this.templeteName = 'danger';
						}
						const container = document.createElement('div');
						const id = `logStatusButton${this.counter}`;
						container.id = id;
						// container.className = 'logButtonStatus'
						container.setAttribute('rowId', row + "");
						container.style.border = 'none';
						htmlElement.appendChild(container);

						var aiAssistSer = this.aiAssist;
						var jobList = this;

						const options = {
							theme: 'viq-theme',
							width: '100%', height: 40, template: 'material', value: value,
							textPosition: 'center'
						};

						const myButton = jqwidgets.createInstance(`#${id}`, 'jqxButton', options);

						myButton.addEventHandler('click', function (row): void {
							console.log('row :: ', this.children[1].getAttribute('rowId'));
							const currentButtonValue = this.children[1].innerHTML;
							let dataRecord = jobList.grid.getrowdata(jobList.editrow);
							console.log('job id :: ', dataRecord.id);
							if (currentButtonValue == 'Request') {
								console.log("currentButtonValue  Request");

								aiAssistSer.requestForLogs({ 'jobId': dataRecord.id }).subscribe(res => {
									console.log('Success message :: ', aiAssistSer.getJSONResponse(res).data);
									this.children[1].innerHTML = 'Running';
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
								});
							} else if (currentButtonValue == 'Running') {
								console.log("currentButtonValue Running");
								aiAssistSer.getLogs(dataRecord.id).subscribe(res => {
									const data = aiAssistSer.getJSONResponse(res).data
									console.log('Success message :: ', data);
									if (data.status == 'Complete') {
										this.children[1].innerHTML = 'Open logs';
									} else {
										this.children[1].innerHTML = 'Running';
									}
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
								});
							} else if (currentButtonValue == 'Open logs') {
								console.log("currentButtonValue Open logs ");
								aiAssistSer.getLogs(dataRecord.id).subscribe(res => {
									console.log('Success message :: ', aiAssistSer.getJSONResponse(res).data);
									// jobList.router.navigateByUrl('/logs/' + dataRecord.id);
									window.open('/logs/Speech Job/' + dataRecord.id)
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
									jobList.notifier.notify('error', aiAssistSer.getJSONResponse(err).error.message);
								});
							}
						});

						this.counter++;
					},
					initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
						if (value.toLowerCase() == 'not available') {
							htmlElement.children[1].children[1].innerHTML = 'Request';
						} else if (value.toLowerCase() == 'running') {
							htmlElement.children[1].children[1].innerHTML = 'Running';
						} else if (value.toLowerCase() == 'complete') {
							htmlElement.children[1].children[1].innerHTML = 'Open logs';
						} else {
							htmlElement.children[1].children[1].innerHTML = value;
						}
						console.log('initial :: ', value, ' Html element :: ', htmlElement.children[1].children[1].innerHTML);
					}
				},
			)
			console.log('loaded')
			// this.columns =;logStatus
		} else if (this.jobType == this.transcode_job_type) {
			this.page_title = 'Transcode Jobs List';
			this.url = this.transcode_url;
			this.loadTranscodeJobs();
			this.columns.push(
				{
					text: 'Output Audio Format',
					datafield: 'preset',
					width: this.getCellWidth(),
					cellsalign: 'center',
					align: 'center',
				},
				{
					text: 'Log Status', datafield: 'logStatus', width: this.getCellWidth(),
					filterable: false,
					align: 'center',
					resizeable: false,
					createwidget: (row: number, column: any, value: string, htmlElement: HTMLElement): void => {
						if (value.toLowerCase() == 'not available') {
							value = this.REQUEST;
							this.templeteName = 'primary';
						} else if (value.toLowerCase() == 'running') {
							value = this.RUNNING;
							this.templeteName = 'warning';
						} else if (value.toLowerCase() == 'complete') {
							value = this.COMPLETE;
							this.templeteName = 'success';
						} else {
							this.templeteName = 'danger';
						}
						const container = document.createElement('div');
						const id = `logStatusButton${this.counter}`;
						container.id = id;
						container.setAttribute('rowId', row + "");
						container.style.border = 'none';
						htmlElement.appendChild(container);

						var aiAssistSer = this.aiAssist;
						var jobList = this;

						const options = {
							theme: 'viq-theme',
							width: '100%', height: 40, template: 'viq-theme', value: value,
							textPosition: 'center'
						};

						const myButton = jqwidgets.createInstance(`#${id}`, 'jqxButton', options);

						myButton.addEventHandler('click', function (row): void {
							console.log('row :: ', this.children[1].getAttribute('rowId'));
							const currentButtonValue = this.children[1].innerHTML;
							let dataRecord = jobList.grid.getrowdata(jobList.editrow);
							console.log('job id :: ', dataRecord.id);
							if (currentButtonValue == 'Request') {
								console.log("currentButtonValue  Request");

								aiAssistSer.requestForTranscodeLogs({ 'jobId': dataRecord.id }).subscribe(res => {
									console.log('Success message :: ', aiAssistSer.getJSONResponse(res).data);
									this.children[1].innerHTML = 'Running';
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
								});
							} else if (currentButtonValue == 'Running') {
								console.log("currentButtonValue Running");
								aiAssistSer.getTranscodeLogs(dataRecord.id).subscribe(res => {
									const data = aiAssistSer.getJSONResponse(res).data
									console.log('Success message :: ', data);
									if (data.status == 'Complete') {
										this.children[1].innerHTML = 'Open logs';
									} else {
										this.children[1].innerHTML = 'Running';
									}
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
								});
							} else if (currentButtonValue == 'Open logs') {
								console.log("currentButtonValue Open logs ");
								aiAssistSer.getTranscodeLogs(dataRecord.id).subscribe(res => {
									console.log('Success message :: ', aiAssistSer.getJSONResponse(res).data);
									// jobList.router.navigateByUrl('/logs/' + dataRecord.id);
									window.open('/logs/Transcode Job/' + dataRecord.id)
								}, err => {
									console.log('Error message :: ', aiAssistSer.getJSONResponse(err).error);
									jobList.notifier.notify('error', aiAssistSer.getJSONResponse(err).error.message);
								});
							}
						});

						this.counter++;
					},
					initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
						if (value.toLowerCase() == 'not available') {
							htmlElement.children[1].children[1].innerHTML = 'Request';
						} else if (value.toLowerCase() == 'running') {
							htmlElement.children[1].children[1].innerHTML = 'Running';
						} else if (value.toLowerCase() == 'complete') {
							htmlElement.children[1].children[1].innerHTML = 'Open logs';
						} else {
							htmlElement.children[1].children[1].innerHTML = value;
						}
						console.log('initial :: ', value, ' Html element :: ', htmlElement.children[1].children[1].innerHTML);
					}
				},
			)
		}
		return this.jobType;
	}

	loadTranscodeJobs() {
		this.pagenum = 0;
		this.pagesize = 10;
		this.sortorder = 'desc';
		this.sortfield = 'startTime';
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	loadUsers() {
		this.pagenum = 0;
		this.pagesize = 10;
		this.sortorder = 'asc';
		this.sortfield = 'username';
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	loadSpeechJobs() {
		this.pagenum = 0;
		this.pagesize = 10;
		this.sortorder = 'desc';
		this.sortfield = 'startTime';
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}


	Pagechanged(event) { 
		this.pagenum = event.args.pagenum;
		this.pagesize = event.args.pagesize;
		console.log('page changed ;; ', event);
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	Pagesizechanged(event) {
		console.log('page sizechanges :: ', event);
	}

	Sort(event) {
		console.log('sort event :: ', event);
		const field = event.args.sortinformation.sortcolumn;
		if (field) {
			this.sortfield = field;
		} else {
			this.sortfield = 'startTime';
		}

		const order = event.args.sortinformation.sortdirection.ascending;
		if (order) {
			this.sortorder = order ? 'asc' : 'desc';
		} else {
			this.sortorder = 'desc';
		}

		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	Filter(event) {
		console.log('event ', event.args.owner)
		this.searchfilters = [];
		const filters = this.grid.getfilterinformation();
		console.log('filte ', filters)
		for (const filter of filters) {
			for (const inFilter of filter.filter.getfilters()) {
				var data;
				var prev;
				if (filter.datafield.includes('Time')) {
					var operation = 'GREATER_THAN_EQUAL';
					var date_format = 'yyyy-MM-dd 00:00:00'
					console.log('time conversion')
					if (prev && prev == filter.datafield) {
						operation = 'LESS_THAN_EQUAL'
						date_format = 'yyyy-MM-dd 23:59:59'
					}
					prev = filter.datafield;
					data = { 'key': filter.datafield, 'value': this.datePipe.transform(inFilter.value, date_format), 'operation': operation };
				} else {
					data = { 'key': filter.datafield, 'value': inFilter.value };
				}

				this.searchfilters.push(data);
			}
		}
		this.getURL(this.url, this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	async getURL(url, pageNumber, pageSize, sortorder, sortfield, searchfilters) {

		const request_data = {
			'pagenum': pageNumber,
			'pagesize': pageSize,
			'sortorder': sortorder,
			'sortdatafield': sortfield,
			'filters': searchfilters
		};

		const data = this.aiAssist.getTabledata(url, this.mediaType, request_data).subscribe(
			res => {
				const data = this.aiAssist.getJSONResponse(res).data;
				// this.source.beforeprocessing = (data) => {
				// 	console.log('before source :: ', this.source);
				// 	this.source.totalrecords = data.totalElements;
				// 	console.log('after source :: ', this.source);
				// };
				this.source.totalrecords = data.totalElements;
				// this.dataAdapter = new jqx.dataAdapter(this.source);
				// this.source.pagescount = data.totalPages;
				// this.source.totalrecords = data.totalElements;
				// this.source.loadallrecords = true;
				// this.source.pagesize = 5;
				// this.source.pagenum = data.number;
				// for (const record of data.content) {
				// 	record.startTime = this.datePipe.transform(record.startTime, 'yyyy-MM-dd HH:mm:ss', environment.timezone)
				// 	record.endTime = this.datePipe.transform(record.endTime, 'yyyy-MM-dd HH:mm:ss', environment.timezone)
				// }
				// for (const record of data.content) {
				// console.log('startTIme ', record.startTime )
				// }
				this.source.recordendindex = (data.number + 1) * data.size;
				this.source.recordstartindex = ((data.number) * data.size);
				this.source.localdata = data.content;
				this.source.pageSize = data.size;

				console.log('source :: ', this.source);

				if(this.dataAdapter){
					this.dataAdapter.source = this.source;
				}else{
					this.dataAdapter = new jqx.dataAdapter(this.source);
				}
				this.grid.updatebounddata('paging');
				this.grid.updatebounddata('sort');
				this.grid.updatebounddata('filter');

				for( let i in this.columns){
					this.columns[i].width = (100 / this.columns.length).toFixed(2) + '%';
					if(this.columns[i].rendered == undefined){
						console.log('tooltip created');
						this.columns[i].rendered = this.tooltiprenderer;
					}
				}
			}
		);
	}

	myGridOnContextMenu(): boolean {
		console.log('myGridOnContextMenu')
		return true;
	}
	myGridOnRowClick(event: any): void | boolean {
		console.log('myGridOnRowClick')
		this.myMenu.close();
		this.editrow = event.args.rowindex;
		let dataRecord = this.grid.getrowdata(this.editrow);
		this.row = dataRecord;
		if (event.args.rightclick && this.jobType == this.speech_job_type && dataRecord.status == 'COMPLETED') {
			let scrollTop = window.scrollY;
			let scrollLeft = window.scrollX;
			this.myMenu.open(parseInt(event.args.originalEvent.clientX) + 5 + scrollLeft, parseInt(event.args.originalEvent.clientY) + 5 + scrollTop);
			return false;
		}
	}
	editrow;
	refernce_id = '';
	speech_text;
	row;
	@ViewChild('speech', { static: false }) speech: HTMLElement;
	async myMenuOnItemClick(event: any) {
		let args = event.args;
		let rowindex = this.grid.getselectedrowindex();
		if (args.innerHTML == 'Get Speech Draft') {
			// this.editrow = rowindex;
			this.myWindow.position({ x: 60, y: 60 });
			// get the clicked row's data and initialize the input fields.
			let dataRecord = this.grid.getrowdata(this.editrow);
			console.log('job id :: ', dataRecord);
			this.row = dataRecord;
			this.refernce_id = 'Speech Draft for ' + dataRecord.referenceId + ', Start Time : ' + this.datePipe.transform(dataRecord.startTime, 'dd-MMM-yyyy HH:mm:ss') + ', End Time : ' + this.datePipe.transform(dataRecord.endTime, 'dd-MMM-yyyy HH:mm:ss');
			this.myWindow.setTitle('<b>'+this.refernce_id+'</b>')
			const data = new FormData();
			data.append('jobId', dataRecord.id);
			const speech_data = this.aiAssist.getJSONResponse(await this.aiAssist.getSpeechResult(environment.url + '/api/v2/job/result', data).toPromise()).data.transcriptionData.transcript;
			this.speech_text = '';
			for (const obj of speech_data) {
				if (!obj.channel || obj.channel == '1')
					this.speech_text += '<p><b>' + obj.speaker + ' :: </b>' + obj.text + '</p>';
			}
			this.myWindow.open();
		}
	}
}
