import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AiAssistService } from '../services/ai-assist.service';
import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  loading = false;
  submitted = false;
  date = new Date();
  version: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private aiAssistService: AiAssistService,
    private notifierService: NotifierService
  ) { 
    this.version = environment.version;
  }

  ngOnInit() {
    if (this.aiAssistService.isLoggedIn) {
      this.router.navigateByUrl('/transcode-job-list/transcode');
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {
    this.submitted = true;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/transcode-job-list/transcode';

    // // reset alerts on submit
    // this.notifier.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.notifierService.notify("error", "Please enter vaild username and password.");
      return;
    }


    this.aiAssistService.getToken(environment.url, this.f.username.value, this.f.password.value).subscribe(
      res => {
        const login_json_data = this.aiAssistService.getJSONResponse(res);
       //  console.log('login json data :: ', login_json_data)
        const access_token = login_json_data.access_token;
        if (access_token) {
          localStorage.setItem('token', access_token);
          localStorage.setItem('username', this.f.username.value);
          this.router.navigate([this.returnUrl]);
          this.loading = true;
          this.aiAssistService.isLoggedIn = true;
          this.aiAssistService.loadUserDetails();
        } else {
          this.notifierService.notify("error", "Login Failed. Please try with vaild username and password.");
        }
      }, err => {
       //  console.log("error on logon",err);
        this.notifierService.notify("error", "Login Failed. Please try with vaild username and password.");
      }
    );
  }

}
