import { DatePipe } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateVocabItemComponent } from '../create-vocab-item/create-vocab-item.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-vocab-dictionary',
	templateUrl: './vocab-dictionary.component.html',
	styleUrls: ['./vocab-dictionary.component.css']
})
export class VocabDictionaryComponent implements OnInit, AfterContentChecked, AfterViewInit {

	height;
	width;
	user_creation_height;
	tooltipCounter: number = 100000;

	pagenum = 0;
	pagesize = 10;
	sortorder = 'asc';
	sortfield = 'vocabItem';
	searchfilters = [];

	page_title = 'Vocabulary Dictionary';
	@ViewChild('vocabContextMenu', { static: false }) vocabContextMenu;
	@ViewChild('vdgrid', { static: false }) grid: jqxGridComponent;

	constructor(private aiAssist: AiAssistService,
		public dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		private notifierService: NotifierService,
		private datePipe: DatePipe,
		) { }

	getCellWidth() {
		var number_of_columns = 9;
		return (100 / number_of_columns).toFixed(2) + '%';
	}

	@Input() columns: any[] = [
		{
			text: 'Vocab Item',
			datafield: 'vocabName',
			width: this.getCellWidth(),
			cellsalign: 'left',
			align: 'center',
		}
	];

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		////  console.log('window resize :: ', event);
		this.height = event.target.innerHeight - 220;
		this.width = event.target.innerWidth - 600;
		this.user_creation_height = event.target.innerHeight - 45;
	}

	getHeight() {
		this.height = (window.screen.height - 390);
		window.dispatchEvent(new Event('resize'));
	}

	@Input() source: any =
		{
			// url: 'http://localhost:8080/api/v2/jobs/',
			// type : 'POST',
			datafields: [
				{
					name:'id',
					map:'id'
				},
				{
					name: 'vocabName',
					map: 'vocabName'
				}
			],
			datatype: 'json',
			root: 'data',
			cache: false,

		};

	dataAdapter: any;// = new jqx.dataAdapter(this.source);

	ngOnInit() {
		this.getHeight();
	}

	refresh(){
		this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters)
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit() {
		document.addEventListener('contextmenu', event => event.preventDefault());
		this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters)
		this.grid.columnsresize(true);
		this.grid.enabletooltips(true);
	}

	rendergridrows = (params: any): any => {
		return params.data;
	}

	tooltiprenderer = (element: any): void => {
		let id = `toolTipContainer${this.tooltipCounter}`;
		element[0].id = id;
		let content = element[0].innerHTML;
		setTimeout(_ => jqwidgets.createInstance(`#${id}`, 'jqxTooltip', { position: 'mouse', content: content }))
		this.tooltipCounter++;
	}

	async getURL(pageNumber, pageSize, sortorder, sortfield, searchfilters) {
		
		const request_data = {
			'pagenum': pageNumber,
			'pagesize': pageSize,
			'sortorder': sortorder,
			'sortdatafield': sortfield,
			'filters': searchfilters
		};

		const data = this.aiAssist.getVocabList(request_data).subscribe(
			res => {
				const data = this.aiAssist.getJSONResponse(res).data;
				// this.source.totalrecords = data.totalElements;
				// this.source.recordendindex = (data.number + 1) * data.size;
				// this.source.recordstartindex = ((data.number) * data.size);
				this.source.localdata = data;

				this.dataAdapter = new jqx.dataAdapter(this.source);
				this.grid.updatebounddata('paging');
				this.grid.updatebounddata('sort');
				this.grid.updatebounddata('filtering');

				for (let i in this.columns) {
					this.columns[i].width = (100 / this.columns.length).toFixed(2) + '%';
					// if (this.columns[i].rendered == undefined) {
					// 	console.log('tooltip created');
					// 	this.columns[i].rendered = this.tooltiprenderer;
					// }
				}
			}
		);
	}

	Pagechanged(event) {
		this.pagenum = event.args.pagenum;
		this.pagesize = event.args.pagesize;
		console.log('page changed ;; ', event);
		this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	Pagesizechanged(event) {
		console.log('page sizechanges :: ', event);
	}

	Sort(event) {
		console.log('sort event :: ', event);
		const field = event.args.sortinformation.sortcolumn;
		if (field) {
			this.sortfield = field;
		} else {
			this.sortfield = 'vocabItem';
		}

		const order = event.args.sortinformation.sortdirection.ascending;
		if (order) {
			this.sortorder = order ? 'asc' : 'desc';
		} else {
			this.sortorder = 'desc';
		}

		this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	updatefilterconditions = (type: string, defaultconditions: any): string[] => {
		let stringcomparisonoperators = ['CONTAINS'];
		let numericcomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
		let datecomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
		let booleancomparisonoperators = ['EQUAL', 'NOT_EQUAL'];
		switch (type) {
			case 'stringfilter':
				return stringcomparisonoperators;
			case 'numericfilter':
				return numericcomparisonoperators;
			case 'datefilter':
				return datecomparisonoperators;
			case 'booleanfilter':
				return booleancomparisonoperators;
		}
	};

	ready = (): void => {
		let localizationObject = {
			filterstringcomparisonoperators: ['contains'],
			// filter numeric comparison operators.
			filternumericcomparisonoperators: ['less than', 'greater than'],
			// filter date comparison operators.
			filterdatecomparisonoperators: ['less than', 'greater than'],
			// filter bool comparison operators.
			filterbooleancomparisonoperators: ['equal', 'not equal']
		}
		this.grid.localizestrings(localizationObject);
		this.grid.applyfilters();
	}

	Filter(event) {
		this.searchfilters = [];
		const filters = this.grid.getfilterinformation();
		console.log('filte ', filters)
		for (const filter of filters) {
			for (const inFilter of filter.filter.getfilters()) {
				var data;
				var prev;
				if (filter.datafield.includes('Time')) {
					var operation = 'GREATER_THAN_EQUAL';
					console.log('time conversion')
					if (prev && prev == filter.datafield) {
						operation = 'LESS_THAN_EQUAL'
					}
					prev = filter.datafield;
					data = { 'key': filter.datafield, 'value': this.datePipe.transform(inFilter.value, 'yyyy-MM-dd HH:mm:ss'), 'operation': operation };
				} else {
					data = { 'key': filter.datafield, 'value': inFilter.value };
				}

				this.searchfilters.push(data);
			}
		}
		this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
	}

	openDialog(): void {
		this.getHeight();
		const dialogRef = this.dialog.open(CreateVocabItemComponent, {
			width: this.width,
			// height: this.user_creation_height,
			data: { isEditMode : false}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The create vocab dialog was closed');
			if(result)
				this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
		});
	}

	editUserDialog(data): void {
		this.getHeight();
		// this.isEditMode = true;
		const dialogRef = this.dialog.open(CreateVocabItemComponent, {
			width: this.width,
			// height: this.user_creation_height - 30;
			data: { isEditMode: true, vocabItem: data }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
			}
		});
	}

	myGridOnContextMenu(): boolean {
		console.log('userGridOnContextMenu')
		return true;
	}
	selectedRow;
	myGridOnRowClick(event: any): void | boolean {
		this.selectedRow = '';
		console.log('userGridOnRowClick')
		this.vocabContextMenu.close();
		this.selectedRow = event.args.rowindex;
		console.log('selectedRow :: ', this.selectedRow);
		if (event.args.rightclick) {
			let scrollTop = window.scrollY;
			let scrollLeft = window.scrollX;
			this.vocabContextMenu.open(parseInt(event.args.originalEvent.clientX) + 5 + scrollLeft, parseInt(event.args.originalEvent.clientY) + 5 + scrollTop);
			return false;
		}
	}

	async myMenuOnItemClick(event: any) {
		let args = event.args;
		let dataRecord = this.grid.getrowdata(this.selectedRow);
		console.log('selected vocab :: ', dataRecord);
		if (args.innerHTML == 'Delete') {
			this.openConfirmationDialog(dataRecord);
		} else if (args.innerHTML == 'Edit') {
			this.editUserDialog(dataRecord);
		}
	}

	openConfirmationDialog(vocab) {
		this.aiAssist.checkVocab(vocab.id).subscribe((data) => {
			const mappedRulesCount  = this.aiAssist.getJSONResponse(data).data;
			const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					message: mappedRulesCount === 0 ? 'Are you sure want to delete(' + vocab.vocabName + ')?' : "The word you are trying to delete is Mapped in 1 or More rules, Still do you want delete?" ,
					buttonText: {
						ok: 'Delete',
						cancel: 'No'
					},
					isDelete: false,
					vocab: vocab
				}
			});

			dialogRef.afterClosed().subscribe((data: any) => {
				console.log('can delete the user record :: ', data.isDelete);
				if (data.isDelete) {
					const dataRecord = { vocabName: vocab.vocabName, soundsLike: vocab.souundsLike };
					this.aiAssist.deleteVocab(dataRecord).subscribe(res => {
						this.pagenum = 0;
						this.pagesize = 10;
						this.getURL(this.pagenum, this.pagesize, this.sortorder, this.sortfield, this.searchfilters);
						this.notifierService.notify('success', dataRecord.vocabName + ' deleted successfully');
					}, err => {
						var errMsg = 'Unable to delete ' + dataRecord.vocabName + '. Please try after some time.'
						const errData = this.aiAssist.getJSONResponse(err).error;
						if (errData.message)
							errMsg = errData.message;
						this.notifierService.notify('error', errMsg);
					});
				}
			});
		});
	}

}
