import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-post-processing',
  templateUrl: './post-processing.component.html',
  styleUrls: ['./post-processing.component.css']
})
export class PostProcessingComponent implements OnInit {
  page_title = 'Post Processing Words'
  create_only_one_row = true;
  columnsSchema = [
    // {
    //   key: 'isSelected',
    //   type: 'isSelected',
    //   label: '',
    //   sort: false
    // },
    {
      key: 'word',
      type: 'text',
      label: 'Word',
      required: true,
      sort: true
    },
    {
      key: 'normalizedWord',
      type: 'text',
      label: 'Normalized Word',
      required: true,
      sort: true
    },
    {
      key: 'diarizationEnabled',
      type: 'checkbox',
      label: 'Diarization Enabled',
      required: true,
      sort: true
    },
    {
      key: 'groupIndex',
      type: 'number',
      label: 'Group Index',
      required: true,
      sort: true
    }
    ,
    {
      key: 'isEdit',
      type: 'isEdit',
      label: 'Actions',
      sort: false
    }
  ]
  displayedColumns: string[] = this.columnsSchema.map((col) => col.key)

  
  dataSource;
  totalJobs;
  wordFilter = new FormControl();
  normalizedWordFilter = new FormControl();
  diarizationFlagFilter = new FormControl();
  groupIndexFilter = new FormControl();
  pageIndex = 0;
  pageSize = 5;
  filters = [];
  debounce = 1000;
  sortField = 'word';
  sortDirection = 'asc';
  saveOrUpdate = 'Update';

  valid: any = {};
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private aiAssistService: AiAssistService, public dialog: MatDialog, private notifierService: NotifierService) { }

  ngOnInit() {
    this.getPaginationData(this.getPayLoad());

    this.wordFilter.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(value => {
        const index = this.filters.findIndex(i => i.key === 'word');
        if (index !== -1) {
          this.filters.splice(index, 1);
        }
        this.filters.push({"key":'word', "value": value});
        this.getPaginationData({ pagenum: 0, pagesize : this.pageSize, filters : this.filters, sortdatafield : this.sortField, sortorder : this.sortDirection})
        this.paginator.firstPage();
      });

      this.normalizedWordFilter.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(value => {
        const index = this.filters.findIndex(i => i.key === 'normalizedWord');
        if (index !== -1) {
          this.filters.splice(index, 1);
        }
        this.filters.push({"key":'normalizedWord', "value": value});
        this.getPaginationData({ pagenum: 0, pagesize : this.pageSize, filters : this.filters, sortdatafield : this.sortField, sortorder : this.sortDirection})
        this.paginator.firstPage();
      });

      // this.diarizationFlagFilter.valueChanges
      // .pipe(debounceTime(this.debounce), distinctUntilChanged())
      // .subscribe(value => {
      //   const index = this.filters.findIndex(i => i.key === 'diarizationEnabled');
      //   if (index !== -1) {
      //     this.filters.splice(index, 1);
      //   }
      //   this.filters.push({"key":'diarizationEnabled', "value": value == "true" ? true : false});
      //   this.getPaginationData(this.getPayLoad())
      // });

      // this.groupIndexFilter.valueChanges
      // .pipe(debounceTime(this.debounce), distinctUntilChanged())
      // .subscribe(value => {
      //   const index = this.filters.findIndex(i => i.key === 'groupIndex');
      //   if (index !== -1) {
      //     this.filters.splice(index, 1);
      //   }
      //   this.filters.push({"key":'groupIndex', "value": value});
      //   this.getPaginationData(this.getPayLoad())
      // });

  }

  getPayLoad(){
    return { pagenum:this.pageIndex, pagesize : this.pageSize, filters : this.filters, sortdatafield : this.sortField, sortorder : this.sortDirection}
  }

  wordFilterchange(event){
    console.log('event : ', event);
  }

  

  handlePageEvent(event){
    console.log('pagination length', event.length, ' pageIndex', event.pageIndex, ' page size', event.pageSize, ' previousPageIndex', event.previousPageIndex );
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPaginationData(this.getPayLoad())
  }

  handleSortEvent(event){
    console.log('event' +event);
    this.sortField = event.active;
    this.sortDirection = event.direction;
    this.getPaginationData(this.getPayLoad())
  }


  getPaginationData(payload){
    this.create_only_one_row = true;
    this.aiAssistService.getAvailablePPList(payload).subscribe( data => {
      const postProcessingData = this.aiAssistService.getJSONResponse(data).data;
      postProcessingData.content.forEach(element => {
        element['isEdit'] = false;
        // element['isSelected'] = false;
      });
      this.dataSource = new MatTableDataSource(postProcessingData.content)
      this.paginator.length = postProcessingData.totalElements;
      this.paginator.pageIndex = this.pageIndex;
      console.log('postProcessingData', postProcessingData)
    }, error => {
      console.log('Error occurred while loading the post processing details in getPaginationData method. ', error);
    });
  }

  isAllSelected() {
    if(this.dataSource === undefined)
      return false;
    return this.dataSource.data.every((item) => item.isSelected)
  }

  isAnySelected() {
    if(this.dataSource === undefined)
      return false;
    return this.dataSource.data.some((item) => item.isSelected)
  }

  selectAll(event: any) {
    this.dataSource.data = this.dataSource.data.map((item) => ({
      ...item,
      isSelected: event.checked,
    }))
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  removeRow(id: number) {
    this.aiAssistService.checkPP(id).subscribe((data) => {
      const mappedRulesCount = this.aiAssistService.getJSONResponse(data).data;
      this.dialog
        .open(ConfirmationDialogComponent, {data: {
          message: mappedRulesCount === 0 ? 'Are you sure?' : 'The word you are trying to delete is Mapped in 1 or More rules, Still do you want delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }})
        .afterClosed()
        .subscribe(data => {
          if (data.isDelete) {
            this.aiAssistService.deletePP(id).subscribe(() => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: any) => u.id !== id,
              )
              this.totalJobs--;
              this.getPaginationData(this.getPayLoad())
              this.notifierService.notify('success', "Deleted successfuly.");
            })
          }
        });
    })
  }

  removeSelectedRows() {
    const users = this.dataSource.data.filter((u: any) => u.isSelected)
    this.dialog
      .open(ConfirmationDialogComponent)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          // this.userService.deleteUsers(users).subscribe(() => {
            this.dataSource.data = this.dataSource.data.filter(
              (u: any) => !u.isSelected,
            )
          // })
        }
      })
  }

  prepare_for_edit(element){
    element.isEdit = !element.isEdit;
    this.saveOrUpdate = 'Update';
  }

  validate_row(row){
    row.word = row.word.trim();
    row.normalizedWord = row.normalizedWord.trim();
   let is_valid = true;
    if(row.word.length<1){
      is_valid = false;
    }else if(row.normalizedWord.length<1){
      is_valid  = false;
    }else if(row.groupIndex.length<1){
      is_valid = false;
    }

    if(!is_valid){
      this.notifierService.notify('error', "Please fill the mandatory fields or enter valid values.");
    }
    return is_valid;
  }


  editRow(row: any) {
    console.log('row data ' + row);
    if(!this.validate_row(row)){
      return;
    }  
   
    let isNewRowAdded = false;
    let userSuccessMsg = 'Word updated successfully.';
    let errorMessage = 'Error occurred while updating the word. Please contact admin.';
    if(row.id === 0){
      isNewRowAdded = true;
      errorMessage = 'Error occurred while creating the word. Please contact admin.';
    }else{
      this.saveOrUpdate = 'Update';
    }
    this.aiAssistService.createOrUpdatePP(row).subscribe(data => {
      const newOrUpdatedPP = this.aiAssistService.getJSONResponse(data).data;
      row.id = newOrUpdatedPP.id
      row.isEdit = false
      if(isNewRowAdded){
        this.getPaginationData(this.getPayLoad());
        userSuccessMsg = 'New word created successfully.';
      }
      this.notifierService.notify('success', userSuccessMsg);
    }, error => {
      const response = this.aiAssistService.getJSONResponse(error);
      console.log('error' + response)
      if(response.error.message){
        errorMessage = response.error.message;
      }
      this.notifierService.notify('error', errorMessage);
    })
  }

  addRow() {
    this.saveOrUpdate = 'Save';
    if(this.create_only_one_row){
      const newRow: any = {
        id: 0,
        word: '',
        normalizedWord: '',
        diarizationEnabled: '',
        groupIndex: '',
        isEdit: true,
        isSelected: false,
      }
      this.dataSource.data = [newRow, ...this.dataSource.data]
      this.create_only_one_row = false;
    }else{
      this.notifierService.notify('warning', "Only single Row will be allowed.");
    }
  }

}
