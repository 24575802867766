import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AiAssistService } from '../services/ai-assist.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    jwtHelper = new JwtHelperService();
    constructor(
        private router: Router,
        private aiAssist: AiAssistService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = localStorage.getItem("token");
        if(!this.jwtHelper.isTokenExpired(token)){
            this.aiAssist.isLoggedIn = true;
            return true;
        }

        this.aiAssist.isLoggedIn = false;
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        // not logged in so redirect to login page with the return url
       //  console.log('state ;;', state)
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}