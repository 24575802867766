import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import {  MatDatepickerModule} from '@angular/material/datepicker';
import {  MatDialogModule} from '@angular/material/dialog';
import {  MatButtonModule} from '@angular/material/button';
import {  MatButtonToggleModule} from '@angular/material/button-toggle';
import {  MatCardModule} from '@angular/material/card';
import {  MatCheckboxModule} from '@angular/material/checkbox';
import {  MatStepperModule} from '@angular/material/stepper';
import {  MatExpansionModule} from '@angular/material/expansion';
import {  MatGridListModule} from '@angular/material/grid-list';
import {  MatIconModule} from '@angular/material/icon';
import {  MatInputModule} from '@angular/material/input';
import {  MatListModule} from '@angular/material/list';
import {  MatMenuModule} from '@angular/material/menu';
import {  MatPaginatorModule} from '@angular/material/paginator';
import {  MatProgressBarModule} from '@angular/material/progress-bar';
import {  MatSelectModule} from '@angular/material/select';
import {  MatRippleModule} from '@angular/material/core';
import {  MatRadioModule} from '@angular/material/radio';
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {  MatToolbarModule} from '@angular/material/toolbar';
import {  MatTabsModule} from '@angular/material/tabs';
import {  MatTableModule} from '@angular/material/table';
import {  MatSortModule} from '@angular/material/sort';
import {  MatSnackBarModule} from '@angular/material/snack-bar';
import {  MatSlideToggleModule} from '@angular/material/slide-toggle';
import {  MatSliderModule} from '@angular/material/slider';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
   
   exports: [
      CommonModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatListModule,
      MatGridListModule,
      MatInputModule,
      MatFormFieldModule,
      MatSelectModule,
      MatRadioModule,
      MatDatepickerModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatCardModule,
      MatProgressBarModule,
      MatSortModule,
      MatSliderModule,
      MatAutocompleteModule,
      MatProgressSpinnerModule,
      MatDialogModule,
      MatTabsModule,
      MatMenuModule,
      MatExpansionModule,
      MatCheckboxModule
   ],
   providers: [
      MatDatepickerModule,
   ]
})

export class AngularMaterialModule { }