import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { UserListComponent } from '../user-list/user-list.component';
import { FormControl, FormGroup, Validators, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { MustMatch } from 'src/app/helpers/mustmatch';
import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.css']
})
export class UserCreationComponent implements OnInit, AfterViewInit {

  selectedIndex = 0;
  rolesList = [];
  providersList = [];
  srEngineList = ['SPEECHMATICS','AWS', "REV", "WHISPER"]
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  errorMessage;
  headerName = 'Create User';
  submitButtonName = 'Create';

  @ViewChild("firstNameField", { static: false }) firstNameField;

  @ViewChild("oldPasswordField", { static: false }) oldPasswordField;

  form: FormGroup;
  changePasswordForm: FormGroup;

  matcher = new MyErrorStateMatcher();

  constructor(
    public dialogRef: MatDialogRef<UserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private aiAssist: AiAssistService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.data.isEditMode) {
      this.form = this.formBuilder.group({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
        userName: new FormControl({ value: '', disabled: this.data.isEditMode }, [Validators.required]),
        inputAudioDays: new FormControl('', [Validators.required, Validators.min(30)]),
        convertedAudioDays: new FormControl('', [Validators.required, Validators.min(30)]),
        srDraftsDays: new FormControl('', [Validators.required, Validators.min(90)]),
        dbRecordsDays: new FormControl('', [Validators.required, Validators.min(90)])
      });
    } else if (this.data.isChangePasswordMode) {
      this.headerName = 'Change Password';
      this.changePasswordForm = this.formBuilder.group({
        oldPassword: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
        {
          validator: MustMatch('password', 'confirmPassword')
        });
    } else {
      this.form = this.formBuilder.group({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
        userName: new FormControl({ value: '', disabled: this.data.isEditMode }, [Validators.required]),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
        inputAudioDays: new FormControl('30', [Validators.required, Validators.min(30)]),
        convertedAudioDays: new FormControl('30', [Validators.required, Validators.min(30)]),
        srDraftsDays: new FormControl('90', [Validators.required, Validators.min(90)]),
        dbRecordsDays: new FormControl('90', [Validators.required, Validators.min(90)])
      },
        {
          validator: MustMatch('password', 'confirmPassword')
        });
    }
    this.loadRoles();
    this.loadProviders();
  }


  ngAfterViewInit(): void {
    if (this.data.isEditMode) {
      this.headerName = 'Edit User';
      this.submitButtonName = 'Update';
     //  console.log('edit data ;; ', this.data)
      this.form.patchValue({
        firstName: this.data.user.firstName,
        lastName: this.data.user.lastName,
        email: this.data.user.email,
        userName: this.data.user.username,
        inputAudioDays: this.data.user.inputAudioDays,
        convertedAudioDays: this.data.user.convertedAudioDays,
        srDraftsDays: this.data.user.srDraftsDays,
        dbRecordsDays: this.data.user.dbRecordsDays
      });
      this.providers.setValue(this.data.user.providerId);
      this.roles.setValue(this.data.user.roleNames.split(','));
      this.srEngine.setValue(this.data.user.srEngine)
    }
    setTimeout(() => {
      if (this.data.isChangePasswordMode) {
        this.oldPasswordField.nativeElement.focus();
      } else {
        this.firstNameField.nativeElement.focus();
      }
    }, 500);

  }

  roles = new FormControl();

  providers = new FormControl({ value: '', disabled: this.data.isEditMode });

  srEngine = new FormControl();

  loadRoles() {
    this.aiAssist.getRoles().subscribe(res => {
      this.rolesList = this.aiAssist.getJSONResponse(res).data;
    });

  }

  loadProviders() {
    this.aiAssist.getProviders().subscribe(res => {
      this.providersList = this.aiAssist.getJSONResponse(res).data;
    });

  }

  errors(fieldName, error) {
    return this.form.controls[fieldName].hasError(error);
  }

  changePasswordErrors(fieldName, error) {
    return this.changePasswordForm.controls[fieldName].hasError(error);
  }

  get f() { return this.form.controls; }


  get changePasswordF() { return this.changePasswordForm.controls; }

  createUser() {
    this.errorMessage = undefined;
    if (this.form.controls.inputAudioDays.invalid || this.form.controls.convertedAudioDays.invalid
      || this.form.controls.srDraftsDays.invalid || this.form.controls.dbRecordsDays.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty({ onlySelf: true });
     //  console.log('not valid')
      return;
    }
    if (this.roles.invalid) {
      this.roles.markAllAsTouched();
      this.roles.markAsDirty({ onlySelf: true });
     //  console.log('not selected the role')
      return;
    }

    if (this.providers.invalid) {
      this.providers.markAllAsTouched();
      this.providers.markAsDirty({ onlySelf: true });
     //  console.log('not selected the providers')
      return;
    }
    if (this.srEngine.invalid) {
      this.srEngine.markAllAsTouched();
      this.srEngine.markAsDirty({ onlySelf: true });
     //  console.log('not selected the srEngine')
      return;
    }
    var data;
    if (!this.data.isEditMode) {
      data = {
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        email: this.form.controls.email.value,
        userName: this.form.controls.userName.value,
        password: this.form.controls.password.value,
        inputAudioDays: this.form.controls.inputAudioDays.value,
        convertedAudioDays: this.form.controls.convertedAudioDays.value,
        srDraftsDays: this.form.controls.srDraftsDays.value,
        dbRecordsDays: this.form.controls.dbRecordsDays.value,
        roleNames: this.roles.value,
        providerId: this.providers.value,
        srEngine: this.srEngine.value
      }
    } else {
      data = {
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        email: this.form.controls.email.value,
        userName: this.form.controls.userName.value,
        roleNames: this.roles.value,
        inputAudioDays: this.form.controls.inputAudioDays.value,
        convertedAudioDays: this.form.controls.convertedAudioDays.value,
        srDraftsDays: this.form.controls.srDraftsDays.value,
        dbRecordsDays: this.form.controls.dbRecordsDays.value,
        srEngine: this.srEngine.value
      }
    }
   //  console.log('user creation :: ', data);
    this.createUserInDB(data);
  }

  createUserInDB(data: any) {
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('username', data.userName);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('providerId', data.providerId);
    formData.append('roleNames', data.roleNames);
    formData.append('inputAudioDays', data.inputAudioDays);
    formData.append('convertedAudioDays', data.convertedAudioDays);
    formData.append('srDraftsDays', data.srDraftsDays);
    formData.append('dbRecordsDays', data.dbRecordsDays);
    formData.append('srEngineName',data.srEngine);
    if (this.data.isEditMode) {
      formData.append('id', this.data.user.id);
    }
    var userSuccessMsg = '';
    this.aiAssist.createUser(formData).subscribe(res => {
      const response = this.aiAssist.getJSONResponse(res);
     //  console.log('user creation response ', response);
      this.dialogRef.close(response.data);
      userSuccessMsg = response.data.username + ' created successfully';
      if (this.data.isEditMode) {
        userSuccessMsg = response.data.username + ' updated successfully';
      }
      this.notifierService.notify('success', userSuccessMsg);
    }, err => {
      const response = this.aiAssist.getJSONResponse(err);
     //  console.log('user creation response ', response);
      this.errorMessage = response.error.message;
    });
  }

  nextPageValidation(): boolean {
    if (this.form.controls.firstName.invalid || this.form.controls.lastName.invalid ||
      this.form.controls.email.invalid || this.form.controls.userName.invalid) {
      this.makeFormDirty(this.form);
      return false;
    }
    if (!this.data.isEditMode) {
     //  console.log('create user')
      if (this.form.controls.password.invalid || this.form.controls.confirmPassword.invalid) {
       //  console.log('not valid')
        this.makeFormDirty(this.form);
        return false;
      }
    }
    this.selectedIndex = 1;
    return true;
  }

  makeFormDirty(form : FormGroup){
    form.markAllAsTouched();
    form.markAsDirty({ onlySelf: true });
  }

  isFormValid() {
    return this.form.invalid;
  }

  changePassword() {
    this.errorMessage = '';
    if (this.changePasswordForm.valid) {
      const data = new FormData();
      data.append('username', localStorage.getItem("username"));
      data.append('password', this.changePasswordForm.controls.password.value);
      data.append('oldPassword', this.changePasswordForm.controls.oldPassword.value);
      this.aiAssist.changeUserPassword(data).subscribe(res => {
        this.notifierService.notify('success', "Password updated sucessfully.")
        this.dialogRef.close();
      }, err => {
        const data = this.aiAssist.getJSONResponse(err).error;
        this.errorMessage = data.message;
      });
    }
  }

}

export interface Role {
  id;
  name;
  description;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
