import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AiAssistService } from 'src/app/services/ai-assist.service';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RuleCreationComponent } from '../rule-creation/rule-creation.component';
import { UserCreationComponent } from '../user-creation/user-creation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.css']
})
export class RuleComponent implements OnInit {

  rule: any;
  isEditMode: boolean = false;
  @ViewChild('rulegrid', { static: false }) rulegrid;
	@ViewChild('userContextMenu', { static: false }) userContextMenu;

	constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
		private aiAssist: AiAssistService,
		public dialog: MatDialog,
		public notifierService: NotifierService) { }


	ngOnInit() {
		this.loadRules();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	ngAfterViewInit() {
		this.rulegrid.enabletooltips(true);
		document.addEventListener('contextmenu', event => event.preventDefault());
	}

	height;
	width;
	user_creation_height;
	users_url = environment.url + '/api/v2/rule';
	mediaType = 'GET';
	dataAdapter: any;
	page_title = 'Rules List'

	getCellWidth() {
		var number_of_columns = 12;
		return (100 / number_of_columns).toFixed(2) + '%';
	}
	counter: number = 1;
	tooltiprenderer = (element: any): void => {
		let id = `toolTipContainer${this.counter}`;
		element[0].id = id;
		let content = element[0].innerHTML;
		console.log('out tooltip', content);
		setTimeout(_ => jqwidgets.createInstance(`#${id}`, 'jqxTooltip', { position: 'mouse', content: content }))
		this.counter++;
	}

	columns: any[] = [
		{
			text: 'Rule ID',
			datafield: 'ruleId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Instance ID',
			datafield: 'instanceId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Tenant ID',
			datafield: 'tenantId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		},{
			text: 'Organization ID',
			datafield: 'organizationId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		},
		{
			text: 'Department ID',
			datafield: 'departmentId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		},
		{
			text: 'Job Type ID',
			datafield: 'jobTypeId',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Last Modified Date',
			datafield: 'lastModifiedDate',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
      		filtertype: 'range', cellsformat: 'MMM-dd-yyyy HH:mm:ss tt zzz',
			rendered: this.tooltiprenderer
		}, {
			text: 'Last Modified By',
			datafield: 'lastModifiedBy',
			width: this.getCellWidth(),
			cellsalign: 'center',
			align: 'center',
			rendered: this.tooltiprenderer
		}, {
			text: 'Deleted Date',
			datafield: 'deletedDate',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
      		filtertype: 'range', cellsformat: 'MMM-dd-yyyy HH:mm:ss tt zzz',
			rendered: this.tooltiprenderer
		}, {
			text: 'Deleted By',
			datafield: 'deletedBy',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		},{
			text: 'Action',
			datafield: 'action',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		},{
			text: 'SR Config Engines',
			datafield: 'srConfigEngines',
			width: this.getCellWidth(),
			align: 'center',
			cellsalign: 'center',
			rendered: this.tooltiprenderer
		}
	];

	source: any =
		{
			// url: 'http://localhost:8080/api/v2/jobs/',
			// type : 'POST',
			datafields: [
				{
					name: 'ruleId',
					map: 'ruleId'
				}, {
					name: 'instanceId',
					map: 'instanceId'
				}, {
					name: 'tenantId',
					map: 'tenantId'
				}, {
					name: 'organizationId',
					map: 'organizationId',
					type: 'string'
				}, {
					name: 'departmentId',
					map: 'departmentId',
					type: 'string'
				},{
					name: 'jobTypeId',
					map: 'jobTypeId'
				}
				, {
					name: 'lastModifiedBy',
					map: 'lastModifiedBy'
				}, {
					name: 'lastModifiedDate',
					map: 'lastModifiedDate',
          			type: 'date'
				}, {
					name: 'deletedBy',
					map: 'deletedBy'
				}, {
					name: 'deletedDate',
					map: 'deletedDate',
          			type:'date'
				},{
					name: 'action',
					map: 'action'
				},{
					name: 'srConfigEngines',
					map: 'srConfigEngines'
				}
			],
			datatype: 'json',
			root: 'content',
			cache: false,
			sortcolumn: 'instanceId',
    		sortdirection: 'asc'

		};

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		////  console.log('window resize :: ', event);
		this.height = event.target.innerHeight - 220;
		this.width = event.target.innerWidth - 500;
		this.user_creation_height = event.target.innerHeight - 100;
	}

	getHeight() {
		this.height = (window.screen.height - 390);
		window.dispatchEvent(new Event('resize'));
	}

	loadRules() {
		this.aiAssist.getTabledata(this.users_url, this.mediaType, null).subscribe(res => {
			this.source.localdata = this.aiAssist.getJSONResponse(res).data;
			this.dataAdapter = new jqx.dataAdapter(this.source);
		});
	}

	refresh() {
		this.loadRules();
	}

	openDialog(): void {
		this.getHeight();
		this.rule = {}
		this.isEditMode = false;
		const dialogRef = this.dialog.open(RuleCreationComponent, {
			width: this.width,
			height: this.user_creation_height,
			data: { isEditMode: this.isEditMode }
		});
		console.log('rule before ', this.rule)

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			this.rule = result;
			console.log('rule after ', this.rule);
			if (this.rule.ruleId != undefined && this.rule.ruleId != "") {
				this.loadRules();
			}
		});
	}

	getRuleData(id){
		return this.aiAssist.getRule(id).toPromise();
	}

	editRuleDialog(ruleId): void {
		this.router.navigate(['/rule/edit/' + ruleId])
		// this.getHeight();
		// this.rule = {}
		// this.isEditMode = true;
		// const dialogRef = this.dialog.open(RuleCreationComponent, {
		// 	width: this.width,
		// 	// height: this.user_creation_height - 30;
		// 	data: { isEditMode: this.isEditMode, rule: data.data }
		// });
		////  console.log('rule before ', this.rule)

		// dialogRef.afterClosed().subscribe(result => {
		// 	console.log('The dialog was closed');
		// 	this.rule = result;
		// 	console.log('user after ', this.rule);
		// 	if (this.rule.ruleId != undefined && this.rule.ruleId != "") {
		// 		this.loadRules();
		// 	}
		// });
	}

	myGridOnContextMenu(): boolean {
		console.log('userGridOnContextMenu')
		return true;
	}
	selectedRow;
	myGridOnRowClick(event: any): void | boolean {
		this.selectedRow = '';
		console.log('userGridOnRowClick')
		this.userContextMenu.close();
		this.selectedRow = event.args.rowindex;
		console.log('selectedRow :: ', this.selectedRow);
		let dataRecord = this.rulegrid.getrowdata(this.selectedRow);
		console.log('is deleted :: ', dataRecord.deletedBy)
		if (event.args.rightclick && !(dataRecord.deletedBy && dataRecord.deletedBy.trim() != '')) {
			let scrollTop = window.scrollY;
			let scrollLeft = window.scrollX;
			this.userContextMenu.open(parseInt(event.args.originalEvent.clientX) + 5 + scrollLeft, parseInt(event.args.originalEvent.clientY) + 5 + scrollTop);
			return false;
		}
	}

	async myMenuOnItemClick(event: any) {
		let args = event.args;
		let dataRecord = this.rulegrid.getrowdata(this.selectedRow);
		console.log('job id :: ', dataRecord);
		if (args.innerHTML == 'Delete') {
			this.openConfirmationDialog(dataRecord);
		} else if (args.innerHTML == 'Edit') {
			// await this.getRuleData(dataRecord.ruleId)
			this.editRuleDialog(dataRecord.ruleId);
		}
	}

	openConfirmationDialog(rule) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				message: 'Are you sure want to delete(' + rule.ruleId + ')?',
				buttonText: {
					ok: 'Yes',
					cancel: 'No'
				},
				isDelete: false,
				rule: rule
			}
		});

		dialogRef.afterClosed().subscribe((data: any) => {
			console.log('can delete the user record :: ', data.isDelete);
			if (data.isDelete) {
				const dataRecord = data.rule;
				this.aiAssist.deleteRule(dataRecord.ruleId).subscribe(res => {
					this.loadRules();
					this.notifierService.notify('success', 'Rule deleted successfully');
				}, err => {
					var errMsg = 'Unable to delete Rule. Please try after some time.'
					const errData = this.aiAssist.getJSONResponse(err).error;
					if (errData.message)
						errMsg = errData.message;
					this.notifierService.notify('error', errMsg);
				});
			}
		});
	}

}
